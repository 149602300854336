@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

.cardContainer {
  font-family: "Arimo", sans-serif;
}
.cardContainer {
  width: 600px;
}

.header {
  padding: 25px 0px 0px 25px;
}

.header h5 {
  color: #153e90;
  font-size: 32px;

  font-weight: 400;
}

.body .address {
  color: #000000;
  font-size: 22px;

  font-weight: 400;
  padding: 2px 20px 10px 22px;
}

.body .phone {
  color: #000000;
  font-size: 22px;

  font-weight: 400;
  padding: 0px 20px 20px 21px;
}

.locationBlock {
  display: block;
  width: 300px;
  margin: 20px 0px 5px 24px;
  font-size: 24px;

  font-weight: 400;
  text-decoration: none;
  color: #000000;
}

.locationBlock img {
  width: 28px;
  margin-right: 20px;
}

.firstRow {
  display: flex;
  padding: 30px 0px 0px 0px;
  justify-content: space-evenly;
}

.locationUnderline {
  border: 1px solid #000000;
  width: 175px;
  height: 10px;
  margin: 0px 0px 45px 90px;
}

@media (max-width: 1279.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
}

@media (max-width: 575.98px) {
  .cardContainer {
    width: auto;
    margin-bottom: 20px;
  }
  .firstRow {
    padding: 5px 20px 0px 20px;
    flex-direction: column;
  }
}
