@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

.row {
  height: 350px;
}

.firstBar .firstHalf {
  background-color: #f9e0ae;
}

.firstBar .firstHalf h4 {
  font-size: 45px;
  margin-top: 85px;
  color: #7a5656;
  font-family: "Arimo", sans-serif;
}
.firstBar .firstHalf span {
  font-size: 30px;
  margin-left: 170px;
  margin-top: 50px;
  color: #7a5656;
  font-weight: 300;
  font-family: "Arimo", sans-serif;
}

.secondBar .firstHalf {
  background-color: #fc8621;
}

.firstBar .firstHalf img {
  position: absolute;
  top: 188px;
  left: 415px;
  width: 82px;
}

.firstBar .secondHalf img {
  width: 50vw;
  height: 350px;
}

.secondBar .secondHalf img {
  width: 50vw;
  height: 350px;
}

.secondBar .firstHalf img {
  position: absolute;
  top: 84px;
  left: 415px;
  width: 243px;
}

.secondBar .firstHalf h4 {
  font-size: 45px;
  margin-top: 57px;
  color: #ffffff;
  font-family: "Arimo", sans-serif;
}

.secondBar .firstHalf span {
  font-size: 30px;
  margin-left: 78px;
  margin-top: 20px;
  font-weight: 300;
  font-family: "Arimo", sans-serif;
  color: #ffffff;
}

hr {
  width: 100vw;
  border: 2px solid #c24914;
  opacity: 1;
}

.whyToChooseContainer .tagLine {
  color: #a88989;
  font-size: 56px;
  font-weight: 400;
  font-family: "Arimo", sans-serif;
}

.whyToChooseContainer img {
  width: auto;
  height: 115px;
  object-fit: contain;
}

.whyToChooseContent {
  padding: 0px 5%;
}

.whyToChooseContent h4 {
  top: 0px;
  left: 390px;
  font-family: "Arimo", sans-serif;
}
.whyToChooseContent p {
  top: 40px;
  left: 390px;
  font-size: 22px;
  color: #000000a9;
}

.whyToChooseContent button {
  bottom: 0px;
  left: 390px;
  font-size: 21px;
  padding: 8px 28px;
  font-family: "Arimo", sans-serif;
  border-radius: 5px;
}

.firstColumn div {
  margin: 50px 10px;
  height: 275px;
}

.firstColumn div img {
  height: 275px;
}

.seondColumn div {
  margin: 50px 10px;
  height: 600px;
}

.seondColumn div img {
  height: 340px;
}

.seondColumn h4 {
  margin-top: 20px;
}

.customerExperienceContainer {
  width: 85%;
  margin: 40px auto 20px;
}

.customerExperienceContainer h4 {
  color: #000000;
  font-family: "Arimo", sans-serif;
}

.customerExperienceList {
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  font-size: 24px;
  color: #000000;
}

.customerExperienceList p {
  margin-right: 39px;
  color: #000000;
  line-height: 1.1;
}

.customerExperienceList div {
  background-color: #f9e0ae;
  width: 49%;
  height: 280px;
  padding: 25px;
}

.customerExperienceList img {
  width: 120px;
  border: 2px solid #f3d361;
}

.testimonialLine {
  border: 2px solid #f3d361 !important;
  width: 75px;
}

#viewAllTestimonialsButton {
  display: block;
  margin-left: auto;
  margin-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
}

.carousel-indicators {
  left: 10%;
  right: 36%;
}

.carousel-indicators li {
  width: 13px;
  height: 13px;
  border-radius: 100%;
  opacity: 1;
  border: 0px solid #ffffff;
  background-color: #ffffff9d;
  margin: 0px 20px 60px;
}

.carousel-indicators .active {
  border: 0px solid orange;
  background-color: #f9e0ae;
}

.blackOverlayCarousel {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.carousel-caption {
  margin-bottom: 150px;
  left: 10%;
  right: 36%;
}
.carousel-caption h5 {
  font-size: 44px;
  text-shadow: 0px 0px 8px #000000;
  font-family: "Arimo", sans-serif;
}

.carousel-control-prev,
.carousel-control-next {
  width: 6%;
  opacity: 1;
}

.prevIconContainer {
  background-color: #ffd700;
  width: 80px;
  height: 50px;
  z-index: -1;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.offerBoardCarousel {
  width: 270px;
  height: 360px;
  background-color: #ffd700;
  position: absolute;
  z-index: 100;
  top: 50px;
  right: 10%;
  border-radius: 5px;
}

.innerBoxofferBoardCarousel {
  width: 86%;
  height: 88%;
  margin-top: 7%;
  margin-left: 7%;
  border: 2px solid #000000;
}

.innerBoxofferBoardCarousel h5 {
  font-size: 18px;
  margin-left: 8%;
  margin-top: 17px;
  color: #585656 !important;
  font-family: "Arimo", sans-serif;
}

.innerBoxofferBoardCarousel h5 b {
  font-size: 25px;
  color: #000000;
}

#downloadBrochureButton {
  background-color: #3dc5af;
  border: 2px solid #93f6e6;
  display: block;
  position: absolute;
  z-index: 100;
  right: 5%;
  bottom: -20px;
  font-size: 15px;
  padding: 12px 8px;
  font-family: "Arimo", sans-serif;
  border-radius: 5px;
}

.objectCoverImage {
  object-fit: cover;
}

.Home-explore-popup-outer-container {
  position: fixed;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.8);
}

.Home-explore-popup-inner-container {
  background-color: #f5f5f5;
  width: 80%;
  height: 75vw;
  position: relative;
}

.Home-explore-popup-inner-container button {
  background-color: #c24914;
  border: 2px solid #e57200;
  display: block;
  border: none;
  color: #ffffff;
  padding: 5px 25px;
}

.Home-explore-popup-close-container {
  box-shadow: 1px -2px 4px #0000001a;
  padding: 1vh 0px;
  position: absolute;
  width: 100%;
  height: 15%;
  bottom: 0px;
}

.Home-explore-popup-inner-content-container {
  height: 85%;
  width: 100%;
  padding: 5%;
  overflow-y: scroll;
  background-color: #f3f1f1;
}

.whyToChooseContent p {
  font-weight: 400;
  font-family: "Arimo", sans-serif;
}

.getEstimateButton:hover {
  background-color: #ff7f00;
  border: 1px solid #e57200;
}


@media (min-width: 1400px) {
  .whyToChooseContent h4,
  .whyToChooseContent p,
  .whyToChooseContent button {
    left: 50%;
    margin-left: 3%;
    margin-right: 3%;
    border-radius: 5px;
  }
  .carousel-inner img {
    height: 500px;
  }
  .whyToChooseContent p {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

    height: 160px;
    overflow-y: clip;
  }

  .seondColumn div img {
    height: 320px;
  }

  .Home-explore-popup-inner-container {
    height: 23vw;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: auto;
  }
  .carousel-item img {
    height: 460px;
  }
  .prevIconContainer {
    width: 31px;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .carouselExampleIndicators {
    height: 450px;
  }

  .carousel-inner {
    height: 450px;
  }
  .objectCoverImage {
    height: 450px;
  }
  .whyToChooseContent h4,
  .whyToChooseContent p,
  .whyToChooseContent button {
    left: 50%;
    margin-left: 3%;
    margin-right: 3%;
  }

  .whyToChooseContent p {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

    height: 160px;
    overflow-y: clip;
  }

  .seondColumn div img {
    height: 320px;
  }
  .Home-explore-popup-inner-container {
    height: 23vw;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: auto;
  }
  .carousel-item img {
    height: 460px;
  }
  .secondBar .firstHalf h4 {
    font-size: 45px;
    margin-left: 44px;
    margin-top: 41px;
    text-align: left !important;
  }
  .secondBar .firstHalf span {
    font-size: 30px;
    margin-left: 49px;
    margin-top: 20px;
  }
  .secondBar .firstHalf img {
    top: 116px;
    left: 329px;
    width: 243px;
  }
  .prevIconContainer {
    width: 31px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .whyToChooseContent h4,
  .whyToChooseContent p,
  .whyToChooseContent button {
    left: 50%;
    margin-left: 3%;
    margin-right: 3%;
  }

  .whyToChooseContent p {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

    height: 160px;
    overflow-y: clip;
  }
  .seondColumn div img {
    height: 320px;
  }
  .Home-explore-popup-inner-container {
    height: 26vw;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: auto;
  }
  .carousel-item img {
    height: 400px;
  }
  .customerExperienceList div {
    height: 330px;
  }
  .secondBar .firstHalf h4 {
    text-align: left !important;
    font-size: 32px;
    margin-left: 32px;
    margin-top: 36px;
  }
  .secondBar .firstHalf span {
    font-size: 26px;
    margin-left: 32px;
    margin-top: 20px;
  }
  .secondBar .firstHalf img {
    left: 270px;
    width: 200px;
  }
  .prevIconContainer {
    width: 32px;
  }
  .firstBar .firstHalf h4 {
    font-size: 32px;
    margin-top: 56px;
    margin-left: 62px;
    text-align: left !important;
  }
  .firstBar .firstHalf span {
    font-size: 30px;
    margin-left: 62px;
    margin-top: 32px;
  }
  .firstBar .firstHalf img {
    top: 127px;
    left: 340px;
  }
  .prevIconContainer {
    width: 32px;
  }
  .whyToChooseContent {
    padding: 0px 20px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .whyToChooseContent img {
    width: 96vw !important;
  }
  .whyToChooseContent {
    flex-direction: column;
  }
  .whyToChooseContent h4,
  .whyToChooseContent p,
  .whyToChooseContent button {
    left: 50%;
    margin-left: 3%;
    margin-right: 3%;
  }

  .firstColumn div {
    height: 350px;
  }
  .firstColumn div img {
    height: 350px;
  }
  .seondColumn {
    width: 100%;
  }
  .seondColumn div {
    height: 650px;
  }
  .firstBar .firstHalf h4 {
    font-size: 34px;
    margin-top: 55px;
    color: #7a5656;
    font-family: "Arimo", sans-serif;
    width: 80%;
    margin-left: 10%;
  }

  .firstBar .firstHalf span {
    font-size: 24px;
    margin-left: 28%;
    margin-top: 20px;
    color: #7a5656;
    font-weight: 300;
    font-family: "Arimo", sans-serif;
  }

  .firstBar .firstHalf img {
    position: static;
    top: 188px;
    margin-left: 39%;
    width: 82px;
  }

  .secondBar .firstHalf h4 {
    font-size: 34px;
    margin-top: 38px;
    color: #ffffff;
    font-family: "Arimo", sans-serif;
    width: 80%;
    margin-left: 10%;
  }

  .secondBar .firstHalf span {
    font-size: 24px;
    margin-left: -6%;
    margin-top: 20px;
    font-weight: 300;
    font-family: "Arimo", sans-serif;
    color: #ffffff;
    text-align: center;
  }

  .secondBar .firstHalf img {
    position: static;
    top: 162px;
    margin-left: 35%;
    width: 119px;
  }

  .firstColumn {
    width: 98%;
    display: flex;
    flex-direction: column;
  }

  .firstColumn div {
    height: auto;
  }

  .firstColumn div img {
    height: auto;
    width: 100% !important;
  }

  .whyToChooseContent h4 {
    top: 0px;
    left: 0px;
    margin-top: 40px;
    font-family: "Arimo", sans-serif;
  }

  .whyToChooseContent h4,
  .whyToChooseContent p,
  .whyToChooseContent button {
    position: static !important;
  }

  .seondColumn div {
    height: auto;
    width: 100%;
  }

  .seondColumn div img {
    height: auto;
    width: 100% !important;
  }
  .customerExperienceList {
    display: flex;
    flex-direction: column;
  }
  .customerExperienceList div {
    width: 100%;
  }
  div#customer_box_2 {
    margin-top: 20px;
  }
  .offerBoardCarousel {
    display: none;
  }

  .carousel-inner img {
    height: 400px;
  }
  .seondColumn div img {
    height: 320px;
  }

  .whyToChooseContent p {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

    height: 160px;
    overflow-y: clip;
  }
  .Home-explore-popup-inner-container {
    height: 36vw;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: auto;
  }
  .whyToChooseContainer img {
    width: 250px !important;
    height: 115px;
    object-fit: contain;
  }
  .whyToChooseContainer .tagLine {
    font-size: 40px;
  }
}
/* Medium devices (tablets, less than 992px) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .carousel-caption h5 {
    font-size: 44px;
    text-shadow: 2px 4px #00000073;
  }
  .carousel-caption {
    margin-bottom: auto;
    top: 40px;
    left: 100px;
    width: 400px;
  }

  .firstBar .firstHalf h4 {
    font-size: 38px;
    margin-top: 30px;
  }

  .firstBar .firstHalf span {
    margin-top: 30px;
    text-align: center;
    margin-left: auto;
  }

  .firstBar .firstHalf img {
    top: 238px;
    left: 145px;
    width: 62px;
  }

  .secondBar .firstHalf h4 {
    font-size: 38px;
    margin-top: 35px;
  }
  .secondBar .firstHalf span {
    margin-left: auto;
    text-align: center;
    margin-top: 20px;
  }
  .secondBar .firstHalf img {
    left: 135px;
    top: 212px;
    width: 120px;
  }
  .whyToChooseContent {
    flex-direction: column;
  }
  .whyToChooseContainer .tagLine {
    font-size: 36px;
  }
  .whyToChooseContent h4 {
    left: 53%;
  }
  .whyToChooseContent p {
    left: 53%;
    font-size: 21px;
  }
  .whyToChooseContent button {
    left: 53%;
  }
  .firstColumn {
    width: auto;
  }
  .seondColumn {
    width: auto;
  }
  .seondColumn div {
    margin: 2px 10px;
    height: 540px;
  }
  .customerExperienceList {
    flex-direction: column;
    margin-top: 20px;
  }
  .customerExperienceList div {
    width: auto;
    margin-top: 20px;
  }
  .prevIconContainer {
    width: 32px;
  }
  .offerBoardCarousel {
    display: none;
  }
  .carousel-indicators li {
    margin: 0px 20px 30px;
  }
  .bottomRectanglefirstColumn {
    padding-left: 20px;
  }
  .carousel-indicators {
    margin-left: 0%;
  }
  .firstBar {
    display: flex;
    flex-direction: column;
  }
  .secondBar {
    display: flex;
    flex-direction: column-reverse;
  }
  .firstBar .firstHalf,
  .firstBar .secondHalf,
  .firstBar .secondHalf img,
  .secondBar .firstHalf,
  .secondBar .secondHalf,
  .secondBar .secondHalf img {
    width: 100%;
  }
  .whyToChooseContent p {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

    height: 160px;
    overflow-y: clip;
    margin-top: 20px;
  }
  .seondColumn div img {
    height: 250px;
  }
  .Home-explore-popup-inner-container {
    height: 46vw;
  }
  /*  */

  .firstBar .firstHalf img,
  .secondBar .firstHalf img {
    position: static;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: auto;
  }
  .firstBar .firstHalf span br {
    display: none;
  }
  .whyToChooseContainer img {
    width: auto;
    height: 70px;
    object-fit: contain;
  }
}

@media (max-width: 575.98px) {
  .carousel-caption h5 {
    font-size: 26px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: auto;
  }
  .firstBar {
    display: flex;
    flex-direction: column;
    background-color: yellowgreen;
  }
  .firstBar .firstHalf {
    width: 100%;
  }
  .firstBar .secondHalf img {
    width: 100vw;
  }

  .secondBar {
    display: flex;
    flex-direction: column-reverse;
  }
  .secondBar .firstHalf {
    width: 100%;
  }
  .secondBar .secondHalf img {
    width: 100vw;
  }

  .whyToChooseContent {
    display: flex;
    flex-direction: column;
  }

  .whyToChooseContent .firstColumn {
    width: 100vw;
  }

  .whyToChooseContent h4 {
    left: 0px;
    margin-top: 25px;
  }

  .whyToChooseContent p {
    left: 0px;
  }

  .whyToChooseContent button {
    left: 0px;
  }

  .whyToChooseContent img {
    width: 96vw !important;
  }

  .whyToChooseContent .seondColumn {
    width: 100vw;
  }
  .customerExperienceList {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0px;
  }

  .customerExperienceList div {
    width: 100%;
    margin-top: 20px;
  }

  .customerExperienceList p {
    margin-top: 20px;
    font-size: 20px;
    position: static !important;
    margin-right: 0px;
    text-align: justify;
    font-weight: 300;
    color: #585656;
  }

  .prevIconContainer {
    background-color: #ffd700;
    width: 30px;
    height: 50px;
    z-index: -1;
  }

  .whyToChooseContent {
    padding: 0;
  }
  .carousel {
    height: 400px;
  }
  .carousel-item img {
    height: 400px;
  }

  .offerBoardCarousel {
    width: 80vw;
    display: none;
  }

  .innerBoxofferBoardCarousel h5 br {
    display: none;
  }
  .innerBoxofferBoardCarousel h5 {
    margin-top: 1%;
  }

  .innerBoxofferBoardCarousel button {
    position: static;
    margin-left: auto;
  }

  .offerBoardCarousel {
    height: 200px;
  }
  .innerBoxofferBoardCarousel {
    height: 76%;
  }
  .carousel-caption {
    margin-bottom: 150px;
    left: 0;
    right: 0;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  .carousel-indicators {
    left: 0;
    right: 0;
  }

  #downloadBrochureButton {
    right: 1%;
    bottom: 8px;
  }

  .firstBar .firstHalf h4 {
    margin-top: 35px;
    font-size: 26px;
  }

  .firstBar .firstHalf span {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 22px;
  }

  .firstBar .firstHalf img {
    display: block;
    margin: 20px auto;
    position: static;
  }

  .secondBar .firstHalf h4 {
    font-size: 26px;
    margin-top: 35px;
  }

  .secondBar .firstHalf span {
    margin: 0px auto;
    font-size: 22px;
    text-align: center;
  }

  .secondBar .firstHalf img {
    position: static;
    display: block;
    margin: 20px auto;
  }

  .firstColumn div {
    display: flex;
    flex-direction: column;
    position: static !important;
    height: auto;
  }

  .firstColumn div img,
  .firstColumn div h4,
  .firstColumn div p,
  .firstColumn div button {
    position: static !important;
    font-size: 18px;
    -webkit-line-clamp: 6;
  }
  .firstColumn div button {
    width: auto;
  }

  .seondColumn div {
    margin-top: 0px;
    height: auto;
  }

  .seondColumn div button {
    position: static !important;
    width: 100%;
    font-size: 18px;
  }

  .whyToChooseContainer .tagLine {
    font-size: 26px;
  }

  .whyToChooseContainer img {
    width: 214px;
    height: 91px;
  }

  .firstColumn div {
    margin: 20px 10px 50px 10px;
  }

  .whyToChooseContent p {
    font-size: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;

    height: 160px;
    overflow-y: clip;
  }
  .Home-explore-popup-inner-container {
    height: 65vw;
  }
  .customerExperienceList div {
    height: auto;
  }
  .customerExperienceBox1 h5,
  .customerExperienceBox1 hr {
    position: static !important;
    font-size: 18px;
    margin-top: 10px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: auto;
  }
}
