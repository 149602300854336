@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

.OurCapabilities-root-container {
  font-family: "Arimo", sans-serif;
}

.firstBarOurCapabilities {
  background-color: #ff6f3c;
}

.firstBarOurCapabilities .firstHalf {
  background-color: #ff6f3c;
}

.firstBarOurCapabilities .secondHalf img {
  width: 50vw;
  height: 350px;
}
.secondBarCapabilities .secondHalf img {
  width: 50vw;
  height: 350px;
}

.firstBarOurCapabilities .firstHalf span {
  margin-left: 85px;
  margin-top: 20px;
  margin-right: 45px;
  font-size: 24px;
  line-height: 1.1;
  color: #ffffff;

  font-weight: 300;
}

.firstBarOurCapabilities .firstHalf h4 {
  font-size: 42px;
  margin-top: 45px;
  margin-left: 85px;
  color: #ffffff;

  font-weight: 500;
}

.secondBarCapabilities .firstHalf span {
  color: #000;
  font-size: 24px;
  line-height: 1.1;
  margin-left: 85px;
  margin-top: 100px;
  margin-right: 45px;

  font-weight: 300;
}
.secondBarCapabilities .firstHalf {
  background-color: #ff9a3c;
}

.ourFeatuesCapabilitiesFirstBar {
  width: 100vw;
  padding: 50px 35px 10px 65px;
}

.ourFeatuesCapabilitiesFirstBar .firstBarHalfFeaturesCapabilities {
  width: 39vw;
}

.ourFeatuesCapabilitiesFirstBar .firstBarHalfFeaturesCapabilities h3 {
  font-size: 43px;
  color: #155263;

  font-weight: 500;
}

.ourFeatuesCapabilitiesFirstBar .firstBarHalfFeaturesCapabilities h5 {
  color: #000000;
  font-size: 30px;
  font-weight: 300;
}

.ourFeatuesCapabilitiesFirstBar .firstBarHalfFeaturesCapabilities p {
  color: #000000a9;
  font-size: 24px;
  font-weight: 300;

  margin-top: 20px;
  margin-right: 20px;
  line-height: 1.2;
}

.ourFeatuesCapabilitiesFirstBar .secondBarHalfFeaturesCapabilities img {
  width: 54vw;
  height: 375px;
}
.hrLineourCapabilitiesOne {
  border: 1px solid #ffc93c;
  margin-top: 15px;
  margin-left: 35px;
  width: 1210px;
}

.projectsYearsContainer .left .firstBarHalfFeaturesCapabilities {
  width: 47vw;
}

.projectsYearsContainer .left .firstBarHalfFeaturesCapabilities h3 {
  color: #155263;
  margin-left: 40px;
  font-size: 41px;

  font-weight: 500;
  margin-top: 30px;
}

.projectsYearsContainer .left .firstBarHalfFeaturesCapabilities h5 {
  color: #000000;
  font-size: 30px;
  font-weight: 300;
  margin-left: 40px;
}

.projectsYearsContainer .left .firstBarHalfFeaturesCapabilities p {
  color: #000000a9;
  font-size: 26px;
  font-weight: 300;

  margin-top: 30px;
  line-height: 1.2;
  margin-left: 40px;
}

.projectsYearsContainer .left .secondBarHalfFeaturesAbout img {
  width: 50vw;
  height: 375px;
  padding-right: 20px;
  padding-left: 20px;
}

.projectsYearsContainer {
  display: flex;
  margin-top: 30px;
  position: relative;
  width: 50vw;
}

.projectsYearsContainer .left {
  width: 50vw;
}

.projectsYearsContainer .right {
  width: 49vw;
}

.projectsYearsContainer .right .firstBarHalfFeaturesAbout {
  width: 46vw;
}

.projectsYearsContainer .right .firstBarHalfFeaturesCapabilities h3 {
  color: #155263;
  margin-left: 55px;
  font-size: 41px;

  font-weight: 500;
}

.projectsYearsContainer .right .firstBarHalfFeaturesCapabilities h5 {
  color: #000000;
  font-size: 30px;
  font-weight: 300;
  margin-left: 55px;
}

.projectsYearsContainer .right .firstBarHalfFeaturesCapabilities p {
  color: #000000a9;
  font-size: 26px;
  font-weight: 300;

  margin-top: 30px;
  line-height: 1;
  margin-left: 55px;
  margin-right: 20px;
}

.projectsYearsContainer .right .secondBarHalfFeaturesAbout img {
  width: 50vw;
  height: 375px;
  padding-right: 35px;
  padding-left: 40px;
  margin-top: 15px;
}

.projectsYearsContainer .center {
  width: 1%;
  height: 62vw;
}

.projectsYearsContainer .center hr {
  width: 2px;
  height: 63vw;
  border: 1px solid #ffc93c;
  position: absolute;
  top: -48px;
}

.hassleFreeContainer {
  margin-top: 60px;
  position: relative;
}

.hassleFreeContainer .firstSection .firstImage {
  width: 30vw;
}
.hassleFreeContainer .firstSection .secondImage {
  width: 30vw;
}
.hassleFreeContainer .firstSection .thirdImage {
  width: 30vw;
}

.hassleFreeContainer .firstSection .firstImage img {
  width: 30vw;
  height: 17vw;
}

.hassleFreeContainer .firstSection .firstImage p {
  font-size: 23px;
  font-weight: 300;

  color: #000;
  line-height: 1.3;
  margin-top: 30px;
  text-align: center;
}

.hassleFreeContainer .firstSection .secondImage p {
  font-size: 23px;
  font-weight: 300;

  color: #000;
  line-height: 1.3;
  margin-top: 30px;
  text-align: center;
}

.hassleFreeContainer .firstSection .thirdImage p {
  font-size: 23px;
  font-weight: 300;

  color: #000;
  line-height: 1.3;
  margin-top: 30px;
  text-align: center;
}

.hassleFreeContainer .firstSection .secondImage img {
  width: 30vw;
  height: 17vw;
}

.hassleFreeContainer .firstSection .thirdImage img {
  width: 30vw;
  height: 17vw;
}

.hassleFreeContainer .firstSection,
.thirdSection {
  display: flex;
  justify-content: space-evenly;
  margin-left: 2vw;
  margin-right: 2vw;
}

.hassleFreeContainer .thirdSection {
  height: 420px;
}

.hassleFreeContainer .thirdSection .firstImage {
  width: 46vw;
}
.hassleFreeContainer .thirdSection .secondImage {
  width: 46vw;
}

.hassleFreeContainer .thirdSection .firstImage img {
  width: 46vw;
  height: 24vw;
}

.hassleFreeContainer .thirdSection .secondImage img {
  width: 46vw;
  height: 24vw;
}

.hassleFreeContainer .thirdSection .center {
  width: 1vw;
  height: 62vw;
}

.hassleFreeContainer .thirdSection .center hr {
  width: 2px;
  height: 26vw;
  border: 1px solid #ffc93c;
  position: absolute;
  top: 350px;
  left: 640px;
}

.hassleFreeContainer p {
  color: #000000;
  font-size: 24px;
  font-weight: 300;

  margin-top: 20px;
  line-height: 1;
  letter-spacing: 0px;
  text-align: center;
}

.hassleFreeTagline {
  color: #ff6f3c;

  font-weight: 500;
  font-size: 32px;
  font-style: italic;
  letter-spacing: 0px;
  text-align: center;
}

@media (min-width: 1400px) {
  .ourFeatuesCapabilitiesFirstBar {
    width: 1400px;
    padding: 50px 35px 10px 65px;
    margin: 0px auto;
  }
  .ourFeatuesCapabilitiesFirstBar .secondBarHalfFeaturesCapabilities img {
    width: 100%;
  }
  .ourFeatuesCapabilitiesFirstBar .firstBarHalfFeaturesCapabilities {
    width: 60%;
  }
  .secondBarHalfFeaturesCapabilities {
    width: 40%;
  }
  .hrLineourCapabilitiesOne {
    width: auto;
  }
  .projectsYearsContainer {
    width: 1400px;
    margin: 30px auto;
  }
  .projectsYearsContainer .left {
    width: 49%;
  }
  .projectsYearsContainer .left .secondBarHalfFeaturesAbout img {
    width: 100%;
  }
  .projectsYearsContainer .right {
    width: 49%;
  }
  .projectsYearsContainer .right .secondBarHalfFeaturesAbout img {
    width: 100%;
  }
  .ourcapabilities-image-container {
    width: 100%;
  }
}

.firstBarOurCapabilities i {
  color: #ffd700;
}

@media (min-width: 1400px) {
  .projectsYearsContainer .left .firstBarHalfFeaturesCapabilities {
    width: 88%;
  }
  .projectsYearsContainer .center {
    width: 1vw;
    height: 900px !important;
  }
  .projectsYearsContainer .center hr {
    height: 900px;
  }
  .hassleFreeContainer .firstSection .firstImage img,
  .hassleFreeContainer .firstSection .secondImage img,
  .hassleFreeContainer .firstSection .thirdImage img {
    width: 96%;
    height: 300px;
    object-fit: cover;
  }
  .hassleFreeContainer {
    width: 1400px;
    margin: 0px auto;
  }
  .hassleFreeContainer .thirdSection .firstImage img,
  .hassleFreeContainer .thirdSection .secondImage img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .hassleFreeContainer .thirdSection .center hr {
    width: 2px;
    height: 500px;
    border: 1px solid #ffc93c;
    position: absolute;
    top: auto;
    left: auto;
    margin: 3px;
  }
  .hassleFreeContainer .thirdSection .center {
    width: 1%;
    height: auto;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .hrLineourCapabilitiesOne {
    width: 95%;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .hrLineourCapabilitiesOne {
    width: 95%;
  }
  .hassleFreeContainer .thirdSection .center hr {
    position: static;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .hrLineourCapabilitiesOne {
    width: 95%;
  }
  .hassleFreeContainer .thirdSection .center hr {
    position: static;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 767.98px) and (min-width: 576px) {
  .firstBarOurCapabilities .firstHalf h4 {
    font-size: 33px;
    margin-top: 25px;
    margin-left: 25px;
  }
  .firstBarOurCapabilities .firstHalf span {
    margin-left: 25px;
    font-size: 20px;
  }
  .secondBarCapabilities .firstHalf span {
    font-size: 20px;
    margin-left: 35px;
  }
  .projectsYearsContainer .right .firstBarHalfFeaturesCapabilities h3,
  .projectsYearsContainer .left .firstBarHalfFeaturesCapabilities h3 {
    margin-left: 25px;
    font-size: 33px;
  }
  .projectsYearsContainer .right .firstBarHalfFeaturesCapabilities h5,
  .projectsYearsContainer .left .firstBarHalfFeaturesCapabilities h5 {
    margin-left: 25px;
    font-size: 22px;
  }

  .projectsYearsContainer .right .firstBarHalfFeaturesCapabilities p,
  .projectsYearsContainer .left .firstBarHalfFeaturesCapabilities p {
    margin-top: 20px;
    margin-left: 25px;
    font-size: 22px;
  }
  .projectsYearsContainer .center hr {
    height: 730px;
  }
  .hrLineourCapabilitiesOne {
    width: 95%;
  }
  .ourFeatuesCapabilitiesFirstBar {
    width: 100vw;
    padding: 40px 35px 10px 35px;
  }
  .ourFeatuesCapabilitiesFirstBar .secondBarHalfFeaturesCapabilities img {
    width: 50vw;
  }
  .ourFeatuesCapabilitiesFirstBar .firstBarHalfFeaturesCapabilities p {
    font-size: 22px;
  }
  .ourFeatuesCapabilitiesFirstBar .firstBarHalfFeaturesCapabilities h5 {
    font-size: 24px;
  }
  .ourFeatuesCapabilitiesFirstBar .firstBarHalfFeaturesCapabilities h3 {
    font-size: 33px;
  }
  .hassleFreeTagline {
    font-size: 26px;
    margin-top: 24px;
  }
  .hassleFreeContainer {
    margin-top: 30px;
  }
  .hassleFreeContainer .firstSection .firstImage p,
  .hassleFreeContainer .firstSection .secondImage p,
  .hassleFreeContainer .firstSection .thirdImage p {
    font-size: 18px;
    margin-top: 20px;
    text-align: left;
  }
  .hassleFreeContainer p {
    font-size: 18px;
    margin-top: 20px;
    text-align: left;
  }
  .hassleFreeContainer .thirdSection .center hr {
    height: 38vw;
    top: 270px;
    left: auto;
  }
  .hassleFreeContainer .thirdSection {
    height: 320px;
  }
}

@media (max-width: 575.98px) {
  .firstBarOurCapabilities {
    display: flex;
    flex-direction: column;
  }

  .firstBarOurCapabilities .firstHalf {
    width: auto;
  }

  .firstBarOurCapabilities .firstHalf h4 {
    margin-top: 35px;
    margin-left: 35px;
  }
  .firstBarOurCapabilities .firstHalf span {
    margin-left: 35px;
    margin-bottom: 30px;
  }
  .firstBarOurCapabilities .secondHalf img {
    width: 100vw;
  }

  .secondBarCapabilities {
    display: flex;
    flex-direction: column-reverse;
  }

  .secondBarCapabilities .secondHalf {
    width: auto;
  }
  .secondBarCapabilities .secondHalf img {
    width: 100vw;
  }
  .secondBarCapabilities .firstHalf span {
    margin-left: 35px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .ourFeatuesCapabilitiesFirstBar {
    width: auto;
    padding: 40px 35px 10px 25px;
    flex-direction: column;
  }
  .secondBarCapabilities .firstHalf {
    width: auto;
  }
  .ourFeatuesCapabilitiesFirstBar .firstBarHalfFeaturesCapabilities {
    width: auto;
  }
  .ourFeatuesCapabilitiesFirstBar .secondBarHalfFeaturesCapabilities img {
    width: 90vw;
  }
  .hrLineourCapabilitiesOne {
    margin-left: 25px;
    width: 90vw;
  }
  .projectsYearsContainer .center {
    display: none;
  }
  .projectsYearsContainer {
    display: flex;
    width: 100vw;
    flex-direction: column;
  }
  .projectsYearsContainer .left .secondBarHalfFeaturesAbout img {
    width: 98vw;
    padding-right: 20px;
    padding-left: 26px;
  }
  .projectsYearsContainer .left .firstBarHalfFeaturesCapabilities {
    width: 90vw;
  }
  .projectsYearsContainer .right {
    width: 90vw;
    display: flex;
    flex-direction: column-reverse;
  }
  .projectsYearsContainer .right .firstBarHalfFeaturesCapabilities h3,
  .projectsYearsContainer .right .firstBarHalfFeaturesCapabilities h5,
  .projectsYearsContainer .right .firstBarHalfFeaturesCapabilities p {
    margin-left: 45px;
  }
  .projectsYearsContainer .right .secondBarHalfFeaturesAbout img {
    width: 98vw;
    padding-right: 20px;
    padding-left: 26px;
  }
  .projectsYearsContainer .right .firstBarHalfFeaturesCapabilities h3 {
    margin-top: 30px;
  }

  .hassleFreeTagline {
    margin: 40px 15px 10px;
  }

  .firstSection {
    display: flex;
    flex-direction: column;
  }
  .hassleFreeContainer .firstSection .firstImage img,
  .hassleFreeContainer .firstSection .secondImage img,
  .hassleFreeContainer .firstSection .thirdImage img {
    width: 96vw;
    height: auto;
  }

  .hassleFreeContainer .firstSection .firstImage,
  .hassleFreeContainer .firstSection .secondImage,
  .hassleFreeContainer .firstSection .thirdImage {
    width: 90vw;
  }

  .hassleFreeContainer .thirdSection {
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .hassleFreeContainer .thirdSection .center {
    display: none;
  }

  .hassleFreeContainer .thirdSection .firstImage,
  .hassleFreeContainer .thirdSection .secondImage {
    width: auto;
  }

  .hassleFreeContainer .thirdSection .firstImage img,
  .hassleFreeContainer .thirdSection .secondImage img {
    width: 96vw;
    height: auto;
  }
  .hassleFreeContainer {
    margin-top: 30px;
  }
}
