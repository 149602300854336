@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

.Careers-root-container {
  font-family: "Arimo", sans-serif;
}
.careerHeading {
  font-size: 2.5rem;
  color: #000000b0;
  margin: 50px 0px 40px 80px;

  font-weight: 500;
}

.careerBar {
  background-color: #155263;
  display: flex;
  height: 600px;
}

.careerBar img {
  width: 53vw;
  object-fit: cover;
}

.careerBarTextContent {
  font-size: 1.5rem;
  width: auto;
  margin: 30px 50px 50px 65px;
  color: #ffffff;
}

.careerBarTextTagLine {
  margin: 40px 50px 0px 65px;
  font-size: 2rem;
  color: #ffffff;
  display: block;
}
.careerBarTextMailId {
  margin: 0px 50px 0px 65px;
  font-size: 3rem;
  color: #ffffff;
  display: block;
  position: relative;
  bottom: 15px;
}

.careerBarTextMailIdUnderLine {
  border: 1px solid #ff6f3c;
  width: 195px;
  height: 10px;
  margin: 0px 0px 0px 70px;
}

.currentJobOpeningLine {
  border: 1px solid #ff6f3c;
  margin-top: 100px;
}

.currentJobOpeningHeading {
  color: #000000b4;
  font-size: 34px;
  margin: 40px 0px 30px 80px;

  font-weight: 500;
}

.currentJobOpeningList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 40px 0px 70px 50px;
}

.currentJobOpeningList li {
  margin-right: 100px;

  font-weight: 400;
  font-size: 24px;
  color: #000000b4;
  padding-bottom: 20px;
}

.currentJobOpeningList li img {
  margin-right: 20px;
  width: 16px;
}

.cardContainer {
  background-color: #ffffff !important;
}
.Contact-complete-name img {
  height: auto !important;
  width: 500px !important;
  display: block;
  margin: 20px auto 0px auto;
}

@media (max-width: 1199.98px) and (min-width: 992px) {
  .careerBar img {
    width: 43%;
    object-fit: cover;
  }
  .careerBarTextContent {
    font-size: 20px;
    width: auto;
    margin: 30px 50px 50px 60px;
    color: #ffffff;
  }
}

@media (max-width: 991.98px) and (min-width: 768px) {
  .careerBar {
    background-color: #155263;
    display: flex;
    height: auto;
    flex-direction: column;
    padding-bottom: 30px;
  }
  .careerBar img {
    width: 70%;
    object-fit: cover;
    margin: 20px auto 0px auto;
  }
  .Contact-complete-name img {
    height: auto !important;
    width: 500px !important;
    display: block;
    margin: 20px auto 0px auto;
  }
  .cardContainer {
    background-color: #ffffff !important;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 767.98px) and (min-width: 576px) {
  .careerHeading {
    margin: 30px 0px 40px 40px;
  }
  .careerBar {
    height: auto;
    flex-direction: column;
  }
  .careerBar img {
    width: auto;
    object-fit: cover;
  }
  .careerBarTextContent {
    font-size: 1.2rem;
    width: 300px;
    margin: 65px 50px 50px 65px;
  }
  .currentJobOpeningLine {
    display: none;
  }
  .currentJobOpeningHeading {
    font-size: 28px;
  }
  .currentJobOpeningList li {
    font-size: 22px;
  }
  .careerBar {
    background-color: #155263;
    display: flex;
    height: auto;
    flex-direction: column;
  }
  .Contact-complete-name img {
    height: auto !important;
    width: 500px !important;
    display: block;
    margin: 20px auto 0px auto;
  }
}

@media (max-width: 575.98px) {
  .careerBar {
    display: flex;
    flex-direction: column;
    height: auto;
    padding-bottom: 100px;
  }
  .careerBar img {
    width: 100vw;
    height: auto;
  }
  .careerBarTextContent {
    margin: 65px 50px 50px 65px;
  }
  .currentJobOpeningLine {
    margin-top: 40px;
  }
  .careerHeading {
    margin: 50px 0px 40px 20px;
  }
  .careerBarTextMailId {
    margin: 0px 50px 0px 12px;
    font-size: 28px;
    margin-top: 15px;
  }
  .careerBarTextContent {
    margin: 30px 10px 10px 10px;
  }
  .careerBarTextTagLine {
    margin: 40px 50px 0px 10px;
    font-size: 24px;
  }
  .careerBarTextMailIdUnderLine {
    width: 117px;
    margin: 0px 0px 0px 14px;
  }
  .currentJobOpeningHeading {
    font-size: 30px;
    margin: 40px 0px 30px 20px;
  }
  .currentJobOpeningList {
    margin: 20px 0px 70px 10px;
  }
  .currentJobOpeningList li {
    margin-right: 0px;
  }
  .Contact-complete-name img {
    height: auto !important;
    width: 280px !important;
    display: block;
    margin: 20px auto 0px auto;
  }
  .header {
    padding: 25px 0px 0px 0px !important;
  }
  .generalEnquiryText {
    width: auto;
    font-size: 20px !important;
  }
}
