
.getEstimateContainer{
    position: fixed;
    height: 100%;
    background-color: #000000c4;
    z-index: 101;
    width: 100%;
    top:0;
    left: 0;
    padding: 200px;
    overflow-y: scroll;
}


.getEstimateTypingContainer{
    position: absolute;
    background-color: #ffffff;
    padding: 20px 50px 20px 50px;
    margin: 20px;
    width: 600px;
    height: auto;
    top:10px;
    right: 300px
}

.getEstimateTypingContainer img {
  display: block;
  width: 30px;
  cursor: pointer;
  margin-left: auto;
}

.etEstimateTypingContainer ::placeholder {
  color: red;
  font-size: 2.5rem;
}

  .getEstimateTypingContainer input[type=text],
  .getEstimateTypingContainer input[type=email],
  textarea{
      width: 100%;
      margin-top: 20px;
      background-color: #00000010;
      border-width: 0px;
      padding: 10px 0px 10px 30px;
      font-size: 1.2rem;
  }

  .getEstimateTypingContainer input[type=submit]{
    background-color: #153E90;
    color: #ffffff;
    margin-top: 25px;
    width: 30%;
    border-width: 0px;
    padding: 10px 0px 10px 10px;
    font-size: 1.5rem;
    display: block;
    margin-left:auto;
  }
  
  .getEstimateTypingContainer h5 {
    font-size: 1.5rem;
    color: #0E49B5;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    margin-top: 20px;
  }


  .statusSuccess{
    color: green ;
    font-size: 30px ;
  }


  .statusFailure{
    color: red;
    font-size: 20px;
    position: absolute
  }

  
  
  @media (max-width: 1279.98px){
  
  }
  
  @media (max-width: 991.98px){
      
  }
  
  @media (max-width: 767.98px){
     
  }
  
  @media (max-width: 575.98px){
    .getEstimateTypingContainer {
      position: absolute;
      background-color: #ffffff;
      padding: 20px 50px 20px 50px;
      margin: 20px;
      width: 80vw;
      height: auto;
      top: 10px;
      left: 5vw;
    }
    .getEstimateTypingContainer input[type=submit] {
      width: 30vw;
    }
  }

  