@import url(https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.headerStrip {
  background: #ff4646;
  color: #ffffff;
  font-family: "Arimo", sans-serif;
}

.headerStrip img {
  width: 14px;
}

.headerOfferStrip {
  background: #cca56b;
  color: #ffffff;
  width: auto;
  margin: 0 auto;
  padding: 2px;
  cursor: pointer;
}

.offers_link_header:link {
  text-decoration: none;
  display: none;
}

.headerOfferStrip img {
  width: 20px;
}

nav {
  background-color: #ffffff;
}
.navbar-nav {
  margin-left: auto;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #e57200;
  font-weight: 500;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000;
  font-weight: 400;
}

.navbar-nav button {
  margin-left: 15px;
  margin-right: 15px;
}
.navBarCompanyLogo {
  width: auto;
  height: 110px;
  object-fit: contain;
}

.getEstimateButton {
  background-color: #ff7f00;
  border: 1px solid #e57200;
  border-radius: 0;
  padding: 12px 15px;
  font-family: "Arimo", sans-serif;
}
#navbarNav {
  font-family: "Arimo", sans-serif;
}
#navbar-get-estimate {
  border-radius: 5px;
}
@media (min-width: 1400px) {
  .navBarCompanyLogo {
    margin-left: 20px;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  #navbar-get-estimate {
    position: absolute;
    top: 110px;
    right: 0px;
    z-index: 2;
    border-radius: 5px;
  }
  .navBarCompanyLogo {
    margin-left: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  #navbar-get-estimate {
    position: absolute;
    top: 110px;
    right: 0px;
    z-index: 2;
  }
  .navBarCompanyLogo {
    width: 170px;
    height: 110px;
    object-fit: contain;
  }
  .navBarCompanyLogo {
    margin-left: 10px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .navBarCompanyLogo {
    margin-left: 15px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .navBarCompanyLogo {
    margin-left: 10px;
  }
}

@media (max-width: 575.98px) {
  .navBarCompanyLogo {
    width: 140px;
    height: 60px;
    object-fit: contain;
    margin-left: 10px;
  }
  .carousel-caption h5 {
    font-size: 26px;
  }
}


.getEstimateContainer{
    position: fixed;
    height: 100%;
    background-color: #000000c4;
    z-index: 101;
    width: 100%;
    top:0;
    left: 0;
    padding: 200px;
    overflow-y: scroll;
}


.getEstimateTypingContainer{
    position: absolute;
    background-color: #ffffff;
    padding: 20px 50px 20px 50px;
    margin: 20px;
    width: 600px;
    height: auto;
    top:10px;
    right: 300px
}

.getEstimateTypingContainer img {
  display: block;
  width: 30px;
  cursor: pointer;
  margin-left: auto;
}

.etEstimateTypingContainer ::-webkit-input-placeholder {
  color: red;
  font-size: 2.5rem;
}

.etEstimateTypingContainer :-ms-input-placeholder {
  color: red;
  font-size: 2.5rem;
}

.etEstimateTypingContainer ::placeholder {
  color: red;
  font-size: 2.5rem;
}

  .getEstimateTypingContainer input[type=text],
  .getEstimateTypingContainer input[type=email],
  textarea{
      width: 100%;
      margin-top: 20px;
      background-color: #00000010;
      border-width: 0px;
      padding: 10px 0px 10px 30px;
      font-size: 1.2rem;
  }

  .getEstimateTypingContainer input[type=submit]{
    background-color: #153E90;
    color: #ffffff;
    margin-top: 25px;
    width: 30%;
    border-width: 0px;
    padding: 10px 0px 10px 10px;
    font-size: 1.5rem;
    display: block;
    margin-left:auto;
  }
  
  .getEstimateTypingContainer h5 {
    font-size: 1.5rem;
    color: #0E49B5;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    margin-top: 20px;
  }


  .statusSuccess{
    color: green ;
    font-size: 30px ;
  }


  .statusFailure{
    color: red;
    font-size: 20px;
    position: absolute
  }

  
  
  @media (max-width: 1279.98px){
  
  }
  
  @media (max-width: 991.98px){
      
  }
  
  @media (max-width: 767.98px){
     
  }
  
  @media (max-width: 575.98px){
    .getEstimateTypingContainer {
      position: absolute;
      background-color: #ffffff;
      padding: 20px 50px 20px 50px;
      margin: 20px;
      width: 80vw;
      height: auto;
      top: 10px;
      left: 5vw;
    }
    .getEstimateTypingContainer input[type=submit] {
      width: 30vw;
    }
  }

  
.row {
  height: 350px;
}

.firstBar .firstHalf {
  background-color: #f9e0ae;
}

.firstBar .firstHalf h4 {
  font-size: 45px;
  margin-top: 85px;
  color: #7a5656;
  font-family: "Arimo", sans-serif;
}
.firstBar .firstHalf span {
  font-size: 30px;
  margin-left: 170px;
  margin-top: 50px;
  color: #7a5656;
  font-weight: 300;
  font-family: "Arimo", sans-serif;
}

.secondBar .firstHalf {
  background-color: #fc8621;
}

.firstBar .firstHalf img {
  position: absolute;
  top: 188px;
  left: 415px;
  width: 82px;
}

.firstBar .secondHalf img {
  width: 50vw;
  height: 350px;
}

.secondBar .secondHalf img {
  width: 50vw;
  height: 350px;
}

.secondBar .firstHalf img {
  position: absolute;
  top: 84px;
  left: 415px;
  width: 243px;
}

.secondBar .firstHalf h4 {
  font-size: 45px;
  margin-top: 57px;
  color: #ffffff;
  font-family: "Arimo", sans-serif;
}

.secondBar .firstHalf span {
  font-size: 30px;
  margin-left: 78px;
  margin-top: 20px;
  font-weight: 300;
  font-family: "Arimo", sans-serif;
  color: #ffffff;
}

hr {
  width: 100vw;
  border: 2px solid #c24914;
  opacity: 1;
}

.whyToChooseContainer .tagLine {
  color: #a88989;
  font-size: 56px;
  font-weight: 400;
  font-family: "Arimo", sans-serif;
}

.whyToChooseContainer img {
  width: auto;
  height: 115px;
  object-fit: contain;
}

.whyToChooseContent {
  padding: 0px 5%;
}

.whyToChooseContent h4 {
  top: 0px;
  left: 390px;
  font-family: "Arimo", sans-serif;
}
.whyToChooseContent p {
  top: 40px;
  left: 390px;
  font-size: 22px;
  color: #000000a9;
}

.whyToChooseContent button {
  bottom: 0px;
  left: 390px;
  font-size: 21px;
  padding: 8px 28px;
  font-family: "Arimo", sans-serif;
  border-radius: 5px;
}

.firstColumn div {
  margin: 50px 10px;
  height: 275px;
}

.firstColumn div img {
  height: 275px;
}

.seondColumn div {
  margin: 50px 10px;
  height: 600px;
}

.seondColumn div img {
  height: 340px;
}

.seondColumn h4 {
  margin-top: 20px;
}

.customerExperienceContainer {
  width: 85%;
  margin: 40px auto 20px;
}

.customerExperienceContainer h4 {
  color: #000000;
  font-family: "Arimo", sans-serif;
}

.customerExperienceList {
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  font-size: 24px;
  color: #000000;
}

.customerExperienceList p {
  margin-right: 39px;
  color: #000000;
  line-height: 1.1;
}

.customerExperienceList div {
  background-color: #f9e0ae;
  width: 49%;
  height: 280px;
  padding: 25px;
}

.customerExperienceList img {
  width: 120px;
  border: 2px solid #f3d361;
}

.testimonialLine {
  border: 2px solid #f3d361 !important;
  width: 75px;
}

#viewAllTestimonialsButton {
  display: block;
  margin-left: auto;
  margin-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
}

.carousel-indicators {
  left: 10%;
  right: 36%;
}

.carousel-indicators li {
  width: 13px;
  height: 13px;
  border-radius: 100%;
  opacity: 1;
  border: 0px solid #ffffff;
  background-color: #ffffff9d;
  margin: 0px 20px 60px;
}

.carousel-indicators .active {
  border: 0px solid orange;
  background-color: #f9e0ae;
}

.blackOverlayCarousel {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.carousel-caption {
  margin-bottom: 150px;
  left: 10%;
  right: 36%;
}
.carousel-caption h5 {
  font-size: 44px;
  text-shadow: 0px 0px 8px #000000;
  font-family: "Arimo", sans-serif;
}

.carousel-control-prev,
.carousel-control-next {
  width: 6%;
  opacity: 1;
}

.prevIconContainer {
  background-color: #ffd700;
  width: 80px;
  height: 50px;
  z-index: -1;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.offerBoardCarousel {
  width: 270px;
  height: 360px;
  background-color: #ffd700;
  position: absolute;
  z-index: 100;
  top: 50px;
  right: 10%;
  border-radius: 5px;
}

.innerBoxofferBoardCarousel {
  width: 86%;
  height: 88%;
  margin-top: 7%;
  margin-left: 7%;
  border: 2px solid #000000;
}

.innerBoxofferBoardCarousel h5 {
  font-size: 18px;
  margin-left: 8%;
  margin-top: 17px;
  color: #585656 !important;
  font-family: "Arimo", sans-serif;
}

.innerBoxofferBoardCarousel h5 b {
  font-size: 25px;
  color: #000000;
}

#downloadBrochureButton {
  background-color: #3dc5af;
  border: 2px solid #93f6e6;
  display: block;
  position: absolute;
  z-index: 100;
  right: 5%;
  bottom: -20px;
  font-size: 15px;
  padding: 12px 8px;
  font-family: "Arimo", sans-serif;
  border-radius: 5px;
}

.objectCoverImage {
  object-fit: cover;
}

.Home-explore-popup-outer-container {
  position: fixed;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.8);
}

.Home-explore-popup-inner-container {
  background-color: #f5f5f5;
  width: 80%;
  height: 75vw;
  position: relative;
}

.Home-explore-popup-inner-container button {
  background-color: #c24914;
  border: 2px solid #e57200;
  display: block;
  border: none;
  color: #ffffff;
  padding: 5px 25px;
}

.Home-explore-popup-close-container {
  box-shadow: 1px -2px 4px #0000001a;
  padding: 1vh 0px;
  position: absolute;
  width: 100%;
  height: 15%;
  bottom: 0px;
}

.Home-explore-popup-inner-content-container {
  height: 85%;
  width: 100%;
  padding: 5%;
  overflow-y: scroll;
  background-color: #f3f1f1;
}

.whyToChooseContent p {
  font-weight: 400;
  font-family: "Arimo", sans-serif;
}

.getEstimateButton:hover {
  background-color: #ff7f00;
  border: 1px solid #e57200;
}


@media (min-width: 1400px) {
  .whyToChooseContent h4,
  .whyToChooseContent p,
  .whyToChooseContent button {
    left: 50%;
    margin-left: 3%;
    margin-right: 3%;
    border-radius: 5px;
  }
  .carousel-inner img {
    height: 500px;
  }
  .whyToChooseContent p {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

    height: 160px;
    overflow-y: clip;
  }

  .seondColumn div img {
    height: 320px;
  }

  .Home-explore-popup-inner-container {
    height: 23vw;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: auto;
  }
  .carousel-item img {
    height: 460px;
  }
  .prevIconContainer {
    width: 31px;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .carouselExampleIndicators {
    height: 450px;
  }

  .carousel-inner {
    height: 450px;
  }
  .objectCoverImage {
    height: 450px;
  }
  .whyToChooseContent h4,
  .whyToChooseContent p,
  .whyToChooseContent button {
    left: 50%;
    margin-left: 3%;
    margin-right: 3%;
  }

  .whyToChooseContent p {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

    height: 160px;
    overflow-y: clip;
  }

  .seondColumn div img {
    height: 320px;
  }
  .Home-explore-popup-inner-container {
    height: 23vw;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: auto;
  }
  .carousel-item img {
    height: 460px;
  }
  .secondBar .firstHalf h4 {
    font-size: 45px;
    margin-left: 44px;
    margin-top: 41px;
    text-align: left !important;
  }
  .secondBar .firstHalf span {
    font-size: 30px;
    margin-left: 49px;
    margin-top: 20px;
  }
  .secondBar .firstHalf img {
    top: 116px;
    left: 329px;
    width: 243px;
  }
  .prevIconContainer {
    width: 31px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .whyToChooseContent h4,
  .whyToChooseContent p,
  .whyToChooseContent button {
    left: 50%;
    margin-left: 3%;
    margin-right: 3%;
  }

  .whyToChooseContent p {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

    height: 160px;
    overflow-y: clip;
  }
  .seondColumn div img {
    height: 320px;
  }
  .Home-explore-popup-inner-container {
    height: 26vw;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: auto;
  }
  .carousel-item img {
    height: 400px;
  }
  .customerExperienceList div {
    height: 330px;
  }
  .secondBar .firstHalf h4 {
    text-align: left !important;
    font-size: 32px;
    margin-left: 32px;
    margin-top: 36px;
  }
  .secondBar .firstHalf span {
    font-size: 26px;
    margin-left: 32px;
    margin-top: 20px;
  }
  .secondBar .firstHalf img {
    left: 270px;
    width: 200px;
  }
  .prevIconContainer {
    width: 32px;
  }
  .firstBar .firstHalf h4 {
    font-size: 32px;
    margin-top: 56px;
    margin-left: 62px;
    text-align: left !important;
  }
  .firstBar .firstHalf span {
    font-size: 30px;
    margin-left: 62px;
    margin-top: 32px;
  }
  .firstBar .firstHalf img {
    top: 127px;
    left: 340px;
  }
  .prevIconContainer {
    width: 32px;
  }
  .whyToChooseContent {
    padding: 0px 20px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .whyToChooseContent img {
    width: 96vw !important;
  }
  .whyToChooseContent {
    flex-direction: column;
  }
  .whyToChooseContent h4,
  .whyToChooseContent p,
  .whyToChooseContent button {
    left: 50%;
    margin-left: 3%;
    margin-right: 3%;
  }

  .firstColumn div {
    height: 350px;
  }
  .firstColumn div img {
    height: 350px;
  }
  .seondColumn {
    width: 100%;
  }
  .seondColumn div {
    height: 650px;
  }
  .firstBar .firstHalf h4 {
    font-size: 34px;
    margin-top: 55px;
    color: #7a5656;
    font-family: "Arimo", sans-serif;
    width: 80%;
    margin-left: 10%;
  }

  .firstBar .firstHalf span {
    font-size: 24px;
    margin-left: 28%;
    margin-top: 20px;
    color: #7a5656;
    font-weight: 300;
    font-family: "Arimo", sans-serif;
  }

  .firstBar .firstHalf img {
    position: static;
    top: 188px;
    margin-left: 39%;
    width: 82px;
  }

  .secondBar .firstHalf h4 {
    font-size: 34px;
    margin-top: 38px;
    color: #ffffff;
    font-family: "Arimo", sans-serif;
    width: 80%;
    margin-left: 10%;
  }

  .secondBar .firstHalf span {
    font-size: 24px;
    margin-left: -6%;
    margin-top: 20px;
    font-weight: 300;
    font-family: "Arimo", sans-serif;
    color: #ffffff;
    text-align: center;
  }

  .secondBar .firstHalf img {
    position: static;
    top: 162px;
    margin-left: 35%;
    width: 119px;
  }

  .firstColumn {
    width: 98%;
    display: flex;
    flex-direction: column;
  }

  .firstColumn div {
    height: auto;
  }

  .firstColumn div img {
    height: auto;
    width: 100% !important;
  }

  .whyToChooseContent h4 {
    top: 0px;
    left: 0px;
    margin-top: 40px;
    font-family: "Arimo", sans-serif;
  }

  .whyToChooseContent h4,
  .whyToChooseContent p,
  .whyToChooseContent button {
    position: static !important;
  }

  .seondColumn div {
    height: auto;
    width: 100%;
  }

  .seondColumn div img {
    height: auto;
    width: 100% !important;
  }
  .customerExperienceList {
    display: flex;
    flex-direction: column;
  }
  .customerExperienceList div {
    width: 100%;
  }
  div#customer_box_2 {
    margin-top: 20px;
  }
  .offerBoardCarousel {
    display: none;
  }

  .carousel-inner img {
    height: 400px;
  }
  .seondColumn div img {
    height: 320px;
  }

  .whyToChooseContent p {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

    height: 160px;
    overflow-y: clip;
  }
  .Home-explore-popup-inner-container {
    height: 36vw;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: auto;
  }
  .whyToChooseContainer img {
    width: 250px !important;
    height: 115px;
    object-fit: contain;
  }
  .whyToChooseContainer .tagLine {
    font-size: 40px;
  }
}
/* Medium devices (tablets, less than 992px) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .carousel-caption h5 {
    font-size: 44px;
    text-shadow: 2px 4px #00000073;
  }
  .carousel-caption {
    margin-bottom: auto;
    top: 40px;
    left: 100px;
    width: 400px;
  }

  .firstBar .firstHalf h4 {
    font-size: 38px;
    margin-top: 30px;
  }

  .firstBar .firstHalf span {
    margin-top: 30px;
    text-align: center;
    margin-left: auto;
  }

  .firstBar .firstHalf img {
    top: 238px;
    left: 145px;
    width: 62px;
  }

  .secondBar .firstHalf h4 {
    font-size: 38px;
    margin-top: 35px;
  }
  .secondBar .firstHalf span {
    margin-left: auto;
    text-align: center;
    margin-top: 20px;
  }
  .secondBar .firstHalf img {
    left: 135px;
    top: 212px;
    width: 120px;
  }
  .whyToChooseContent {
    flex-direction: column;
  }
  .whyToChooseContainer .tagLine {
    font-size: 36px;
  }
  .whyToChooseContent h4 {
    left: 53%;
  }
  .whyToChooseContent p {
    left: 53%;
    font-size: 21px;
  }
  .whyToChooseContent button {
    left: 53%;
  }
  .firstColumn {
    width: auto;
  }
  .seondColumn {
    width: auto;
  }
  .seondColumn div {
    margin: 2px 10px;
    height: 540px;
  }
  .customerExperienceList {
    flex-direction: column;
    margin-top: 20px;
  }
  .customerExperienceList div {
    width: auto;
    margin-top: 20px;
  }
  .prevIconContainer {
    width: 32px;
  }
  .offerBoardCarousel {
    display: none;
  }
  .carousel-indicators li {
    margin: 0px 20px 30px;
  }
  .bottomRectanglefirstColumn {
    padding-left: 20px;
  }
  .carousel-indicators {
    margin-left: 0%;
  }
  .firstBar {
    display: flex;
    flex-direction: column;
  }
  .secondBar {
    display: flex;
    flex-direction: column-reverse;
  }
  .firstBar .firstHalf,
  .firstBar .secondHalf,
  .firstBar .secondHalf img,
  .secondBar .firstHalf,
  .secondBar .secondHalf,
  .secondBar .secondHalf img {
    width: 100%;
  }
  .whyToChooseContent p {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

    height: 160px;
    overflow-y: clip;
    margin-top: 20px;
  }
  .seondColumn div img {
    height: 250px;
  }
  .Home-explore-popup-inner-container {
    height: 46vw;
  }
  /*  */

  .firstBar .firstHalf img,
  .secondBar .firstHalf img {
    position: static;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: auto;
  }
  .firstBar .firstHalf span br {
    display: none;
  }
  .whyToChooseContainer img {
    width: auto;
    height: 70px;
    object-fit: contain;
  }
}

@media (max-width: 575.98px) {
  .carousel-caption h5 {
    font-size: 26px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: auto;
  }
  .firstBar {
    display: flex;
    flex-direction: column;
    background-color: yellowgreen;
  }
  .firstBar .firstHalf {
    width: 100%;
  }
  .firstBar .secondHalf img {
    width: 100vw;
  }

  .secondBar {
    display: flex;
    flex-direction: column-reverse;
  }
  .secondBar .firstHalf {
    width: 100%;
  }
  .secondBar .secondHalf img {
    width: 100vw;
  }

  .whyToChooseContent {
    display: flex;
    flex-direction: column;
  }

  .whyToChooseContent .firstColumn {
    width: 100vw;
  }

  .whyToChooseContent h4 {
    left: 0px;
    margin-top: 25px;
  }

  .whyToChooseContent p {
    left: 0px;
  }

  .whyToChooseContent button {
    left: 0px;
  }

  .whyToChooseContent img {
    width: 96vw !important;
  }

  .whyToChooseContent .seondColumn {
    width: 100vw;
  }
  .customerExperienceList {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0px;
  }

  .customerExperienceList div {
    width: 100%;
    margin-top: 20px;
  }

  .customerExperienceList p {
    margin-top: 20px;
    font-size: 20px;
    position: static !important;
    margin-right: 0px;
    text-align: justify;
    font-weight: 300;
    color: #585656;
  }

  .prevIconContainer {
    background-color: #ffd700;
    width: 30px;
    height: 50px;
    z-index: -1;
  }

  .whyToChooseContent {
    padding: 0;
  }
  .carousel {
    height: 400px;
  }
  .carousel-item img {
    height: 400px;
  }

  .offerBoardCarousel {
    width: 80vw;
    display: none;
  }

  .innerBoxofferBoardCarousel h5 br {
    display: none;
  }
  .innerBoxofferBoardCarousel h5 {
    margin-top: 1%;
  }

  .innerBoxofferBoardCarousel button {
    position: static;
    margin-left: auto;
  }

  .offerBoardCarousel {
    height: 200px;
  }
  .innerBoxofferBoardCarousel {
    height: 76%;
  }
  .carousel-caption {
    margin-bottom: 150px;
    left: 0;
    right: 0;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  .carousel-indicators {
    left: 0;
    right: 0;
  }

  #downloadBrochureButton {
    right: 1%;
    bottom: 8px;
  }

  .firstBar .firstHalf h4 {
    margin-top: 35px;
    font-size: 26px;
  }

  .firstBar .firstHalf span {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 22px;
  }

  .firstBar .firstHalf img {
    display: block;
    margin: 20px auto;
    position: static;
  }

  .secondBar .firstHalf h4 {
    font-size: 26px;
    margin-top: 35px;
  }

  .secondBar .firstHalf span {
    margin: 0px auto;
    font-size: 22px;
    text-align: center;
  }

  .secondBar .firstHalf img {
    position: static;
    display: block;
    margin: 20px auto;
  }

  .firstColumn div {
    display: flex;
    flex-direction: column;
    position: static !important;
    height: auto;
  }

  .firstColumn div img,
  .firstColumn div h4,
  .firstColumn div p,
  .firstColumn div button {
    position: static !important;
    font-size: 18px;
    -webkit-line-clamp: 6;
  }
  .firstColumn div button {
    width: auto;
  }

  .seondColumn div {
    margin-top: 0px;
    height: auto;
  }

  .seondColumn div button {
    position: static !important;
    width: 100%;
    font-size: 18px;
  }

  .whyToChooseContainer .tagLine {
    font-size: 26px;
  }

  .whyToChooseContainer img {
    width: 214px;
    height: 91px;
  }

  .firstColumn div {
    margin: 20px 10px 50px 10px;
  }

  .whyToChooseContent p {
    font-size: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;

    height: 160px;
    overflow-y: clip;
  }
  .Home-explore-popup-inner-container {
    height: 65vw;
  }
  .customerExperienceList div {
    height: auto;
  }
  .customerExperienceBox1 h5,
  .customerExperienceBox1 hr {
    position: static !important;
    font-size: 18px;
    margin-top: 10px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: auto;
  }
}

.Aboutus-root-container {
  font-family: "Arimo", sans-serif;
}

.topQuadrantAbout {
  width: 100%;
}

.topQuadrantFirstBarAbout {
  background-color: #fffcca;
  height: 370px;
}

.topQuadrantSecondBarAbout {
  background-color: #537780;
  height: 370px;
}

.topQuadrantFirstBarAbout .firstBarHalfAbout {
  width: 50%;
  padding: 50px 72px 25px 80px;
}

.topQuadrantFirstBarAbout .firstBarHalfAbout h4 {
  color: #000000;
  font-size: 43px;
}

.topQuadrantFirstBarAbout .firstBarHalfAbout p {
  color: #322f2f;
  font-size: 26px;
  font-weight: 300;

  margin-top: 20px;
  line-height: 1;
}

.topQuadrantFirstBarAbout .secondBarHalfAbout img {
  width: 50%;
  height: 370px;
}

.topQuadrantSecondBarAbout .secondBarHalfAbout img {
  width: 50%;
  height: 370px;
}

.topQuadrantSecondBarAbout .firstBarHalfAbout {
  width: 50%;
  padding: 80px 72px 25px 80px;
}

.topQuadrantSecondBarAbout .firstBarHalfAbout p {
  color: #f9e0ae;
  font-size: 26px;
  font-weight: 300;

  margin-top: 20px;
  line-height: 1;
}

.offerStripAbout {
  width: 95%;
  height: auto;
  margin: 20px auto;
  background-color: #d337d3;
  padding: 20px;
  border-radius: 5px;
}

.innerOfferStripAbout {
  border: 2px solid #bfbfbf;
  padding: 20px 5px 4px 5px;
  margin-left: 15px;
  margin-right: 20px;
}

.innerOfferStripAbout h5 {
  font-size: 24px;
  color: #ffffff;
  margin-left: 9px;
  margin-right: 20px;
}

.innerOfferStripAbout button {
  display: block;
  height: 32px;
  margin-left: 75px;
  margin-top: -4px;
  font-size: 15px;
  padding: 6px 12px 5px;
  background-color: #14c245;
  border: none;
  border-radius: 0;
  box-shadow: 0px 3px 3px #00000045;
}

.innerBoxbannerContainerAbout b {
  font-size: 25px;
}

.innerOfferStripAbout span {
  width: 4px;
  height: 35px;
  margin-top: -4px;
  margin-left: 9px;
  background-color: #bfbfbf;
}

.ourFeatuesAboutTagline {
  color: #537780;

  font-style: italic;
  font-weight: 500;
  margin: 50px auto;
  text-align: center;
}

.ourFeatuesAbout {
  width: 100%;
  padding: 15px 95px;
}

.ourFeatuesAboutFirstBar .secondBarHalfFeaturesAbout img {
  width: 50%;
  height: 375px;
  padding-left: 30px;
}

.ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout {
  width: 35%;
}

.ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout h3 {
  font-size: 43px;

  font-weight: 500;
}

.ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout p {
  color: #000000a9;
  font-size: 24px;
  font-weight: 300;

  margin-top: 20px;
  line-height: 1.2;
}

.firstCapitalFeatureAbout {
  font-size: 70px;
}

.readMoreButton {
  background-color: #11d3bc;
  border: 2px solid #13ae9c;
  border-radius: 0;
  padding: 12px 15px;
  width: 170px;
  margin-top: 20px;
  border-radius: 5px;
}

.readMoreButton:hover {
  background-color: #11d3bc;
  border: 2px solid #13ae9c;
}

.ourFeatuesAboutSecondBar {
  margin-top: 40px;
}

.ourFeatuesAboutSecondBar .secondBarHalfFeaturesAbout img {
  width: 50%;
  height: 375px;
  padding-right: 30px;
}

.ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout {
  width: 50%;
}

.ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout p {
  color: #000000a9;
  font-size: 26px;
  font-weight: 300;

  margin-top: 20px;
  line-height: 1;
}

.ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout h3 {
  font-size: 43px;

  font-weight: 500;
}

#ourFeatuesAboutThirdBar {
  margin-top: 40px;
}

#ourFeatuesAboutFourthBar {
  margin-bottom: 40px;
}

.processContainerAbout {
  width: 90%;
  margin: 10px auto;
}

.processContainerAbout h3 {
  font-size: 43px;

  font-weight: 500;
  margin-left: 25px;
  margin-top: 30px;
}

.processParaInAbout {
  font-size: 26px;

  font-weight: 300;
  margin-top: 30px;
  margin-left: 25px;
  color: #000000be;
  text-align: justify;
}

.diagramBannerContainer {
  display: flex;
}

.processContainerAbout .diagramContainerAbout {
  width: 70%;
  height: 610px;
  margin-top: 50px;
  padding-right: 3%;
}

.processContainerAbout .bannerContainerAbout {
  width: 30%;
  background-color: #ffc93c;
  height: 610px;
  margin-top: 50px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.innerBoxbannerContainerAbout {
  width: 86%;
  height: 560px;
  margin-top: 25px;
  margin-left: 25px;
  border: 2px solid #f2910a;
  text-align: center;
}

.innerBoxbannerContainerAbout h4 {
  color: #000000;
  font-size: 31px;
  margin-top: 80px;
  font-family: "Arimo", sans-serif;
  font-weight: 700;
}

.innerBoxbannerContainerAbout b {
  font-size: 60px;
  color: darkmagenta;
}
.innerBoxbannerContainerAbout img {
  width: 185px;
  margin-top: 60px;
}

.firstRowDiagramContainerAbout {
  width: 100%;
}

.blockDiagramContainerAbout {
  width: 28%;
  text-align: center;
}

.blockDiagramContainerAbout img {
  margin: 0px auto;
  width: 50px;
}

.blockDiagramContainerAbout p {
  font-size: 20px;
  margin: 20px auto;
}

.arrowDiagramContainerAbout {
  width: 8%;
}

.arrowDiagramContainerAbout img {
  width: 75%;
  margin-top: 20px;
}

.downArrowDiagramContainerAbout {
  width: 30%;
  margin-left: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.downArrowDiagramContainerAbout img {
  height: 45px;
}

.downArrowLeftDiagramContainerAbout {
  margin-left: 0px;
  width: 28%;
}

.About-us-what-our-designer-do ul li {
  font-size: 18px;
}
.firstBarHalfFeaturesAbout b {
  font-style: italic;
  color: #5f9ea0;
}

@media (min-width: 1400px) {
  .topQuadrantFirstBarAbout .firstBarHalfAbout {
    width: 58%;
    padding: 50px 72px 25px 80px;
  }
  .secondBarHalfAbout {
    width: 42%;
  }
  .topQuadrantFirstBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .topQuadrantSecondBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout {
    width: 58%;
    padding: 80px 72px 25px 80px;
  }
  .offerStripAbout {
    width: 1230px;
  }
  .processContainerAbout {
    width: 1400px;
    margin: 60px auto;
  }
  .ourFeatuesAboutTagline {
    width: 1400px;
    text-align: center;
  }
  .ourFeatuesAbout {
    width: 1400px;
    padding: 15px 95px;
    margin: 30px auto;
  }
  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout {
    width: 52%;
  }
  .ourFeatuesAboutFirstBar .secondBarHalfFeaturesAbout img {
    width: 100%;
  }
  .ourFeatuesAboutSecondBar .secondBarHalfFeaturesAbout img {
    width: 100%;
  }
  .secondBarHalfFeaturesAbout {
    width: 48%;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .ourFeatuesAboutFirstBar .secondBarHalfFeaturesAbout img {
    width: 100%;
    height: 375px;
    padding-left: 30px;
  }
  .ourFeatuesAboutSecondBar .secondBarHalfFeaturesAbout img {
    width: 100%;
    height: 375px;
    padding-right: 30px;
  }
  .topQuadrantFirstBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .topQuadrantSecondBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout p {
    margin-top: 0px;
  }
  element.style {
    width: 58%;
  }
  .contactUsTopPortionText div:nth-child(2) {
    width: 50%;
  }
  #ourFeatuesAboutThirdBar {
    margin-top: 40px;
    display: flex !important;
    justify-content: center;
  }
  .ourFeatuesAboutFirstBar .secondBarHalfFeaturesAbout img {
    width: 500px;
  }
  .ourFeatuesAboutFirstBar {
    justify-content: center;
  }
}

@media (min-width: 991.98px) and (max-width: 1199.98px) {
  .innerOfferStripAbout button {
    height: auto;
    margin-left: 16px;
  }
  .offerStripAbout {
    height: auto;
  }
  .topQuadrantFirstBarAbout .firstBarHalfAbout {
    width: 100%;
    padding: 50px 72px 25px 80px;
  }
  .topQuadrantFirstBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout {
    width: 70%;
    padding: 30px 30px 20px 30px;
  }
  .topQuadrantSecondBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .innerBoxbannerContainerAbout h4 {
    font-size: 23px;
  }
  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout {
    width: 70%;
  }
  .ourFeatuesAbout {
    width: 100%;
    padding: 15px 60px;
  }
  .ourFeatuesAboutFirstBar .secondBarHalfFeaturesAbout img {
    width: 100%;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout {
    width: 70%;
  }
  .ourFeatuesAboutSecondBar .secondBarHalfFeaturesAbout img {
    width: 100%;
    height: 375px;
    padding-right: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .topQuadrantFirstBarAbout .firstBarHalfAbout {
    padding: 40px 32px 5px 20px;
  }
  .topQuadrantFirstBarAbout .firstBarHalfAbout h4 {
    font-size: 33px;
  }
  .topQuadrantFirstBarAbout .firstBarHalfAbout p {
    font-size: 22px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout {
    padding: 40px 32px 25px 30px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout p {
    font-size: 22px;
  }
  .innerOfferStripAbout {
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .innerOfferStripAbout h5 {
    font-size: 18px;
  }
  .offerStripAbout {
    height: auto;
    padding: 10px;
  }

  .innerOfferStripAbout button {
    height: auto;
    margin-left: 0px;
  }

  .processContainerAbout h3 {
    font-size: 33px;
  }
  .processParaInAbout {
    font-size: 26px;
  }
  .diagramBannerContainer {
    flex-direction: column;
  }
  .processContainerAbout .diagramContainerAbout {
    width: auto;
  }
  .processContainerAbout .bannerContainerAbout {
    width: auto;
  }
  .innerBoxbannerContainerAbout h4 {
    margin-top: 20px;
  }
  .innerBoxbannerContainerAbout img {
    width: 185px;
    margin-top: auto;
  }

  .innerBoxbannerContainerAbout {
    height: 425px;
    margin-left: 45px;
  }

  .processContainerAbout .bannerContainerAbout {
    height: 480px;
  }
  .ourFeatuesAbout {
    padding: 15px 35px;
  }

  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout {
    width: 80%;
    padding: 20px;
  }
  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout h3 {
    font-size: 33px;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout h3 {
    font-size: 33px;
  }
  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout p {
    font-size: 20px;
    margin-top: auto;
    text-align: justify;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout p {
    font-size: 20px;
    margin-top: auto;
    text-align: justify;
  }
  .topQuadrantFirstBarAbout .firstBarHalfAbout {
    width: 100%;
    padding: 50px 72px 25px 80px;
  }
  .topQuadrantFirstBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout {
    width: 75%;
    padding: 80px 72px 25px 80px;
  }
  .topQuadrantSecondBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout {
    width: 75%;
    padding: 39px 30px 25px 40px;
  }
  .ourFeatuesAboutFirstBar .secondBarHalfFeaturesAbout img {
    width: 100%;
    height: 375px;
    padding-left: 0px;
    margin-top: 30px;
  }
  .ourFeatuesAboutSecondBar {
    margin-top: 40px;
    flex-direction: column-reverse;
  }
  .ourFeatuesAboutSecondBar .secondBarHalfFeaturesAbout img {
    width: 100%;
    height: 375px;
    padding-right: 30px;
    margin-top: 30px;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout {
    width: 100%;
  }
  #ourFeatuesAboutThirdBar {
    margin-top: 40px;
    flex-direction: column;
  }
  .ourFeatuesAboutTagline {
    margin: 30px auto;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 767.98px) and (min-width: 576px) {
  .topQuadrantFirstBarAbout .firstBarHalfAbout {
    padding: 40px 32px 5px 20px;
  }
  .topQuadrantFirstBarAbout .firstBarHalfAbout h4 {
    font-size: 33px;
  }
  .topQuadrantFirstBarAbout .firstBarHalfAbout p {
    font-size: 22px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout {
    padding: 40px 32px 25px 30px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout p {
    font-size: 22px;
  }
  .innerOfferStripAbout {
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .innerOfferStripAbout h5 {
    font-size: 18px;
  }
  .offerStripAbout {
    height: auto;
    padding: 10px;
  }

  .innerOfferStripAbout button {
    height: auto;
    margin-left: 0px;
  }

  .processContainerAbout h3 {
    font-size: 33px;
  }
  .processParaInAbout {
    font-size: 26px;
  }
  .diagramBannerContainer {
    flex-direction: column;
  }
  .processContainerAbout .diagramContainerAbout {
    width: auto;
  }
  .processContainerAbout .bannerContainerAbout {
    width: auto;
  }
  .innerBoxbannerContainerAbout h4 {
    margin-top: 20px;
  }
  .innerBoxbannerContainerAbout img {
    width: 185px;
    margin-top: auto;
  }

  .innerBoxbannerContainerAbout {
    height: 400px;
    margin-left: 45px;
  }

  .processContainerAbout .bannerContainerAbout {
    height: 450px;
  }
  .ourFeatuesAbout {
    padding: 15px 35px;
  }

  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout {
    width: auto;
  }
  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout h3 {
    font-size: 33px;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout h3 {
    font-size: 33px;
  }
  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout p {
    font-size: 20px;
    margin-top: auto;

    text-align: justify;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout p {
    font-size: 20px;
    margin-top: auto;

    text-align: justify;
  }

  .topQuadrantFirstBarAbout {
    background-color: #fffcca;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .topQuadrantFirstBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .topQuadrantSecondBarAbout {
    background-color: #537780;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
  }
  .topQuadrantSecondBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout {
    width: auto;
    padding: 40px 72px 25px 80px;
  }

  .ourFeatuesAboutFirstBar {
    flex-direction: column;
  }
  .ourFeatuesAboutFirstBar .secondBarHalfFeaturesAbout img {
    width: 100%;
    height: 375px;
    margin-top: 30px;
    padding-left: 0px;
  }
  .ourFeatuesAboutSecondBar {
    margin-top: 40px;
    flex-direction: column-reverse;
  }
  .ourFeatuesAboutSecondBar .secondBarHalfFeaturesAbout img {
    width: 100%;
    height: 375px;
    padding-right: 0px;
    margin-top: 30px;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout {
    width: auto;
  }
}

@media (max-width: 575.98px) {
  .topQuadrantAbout {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .topQuadrantFirstBarAbout,
  .topQuadrantSecondBarAbout {
    height: auto;
    display: flex;
  }

  .topQuadrantFirstBarAbout {
    flex-direction: column;
  }
  .topQuadrantSecondBarAbout {
    flex-direction: column-reverse;
  }

  .topQuadrantFirstBarAbout .firstBarHalfAbout,
  .topQuadrantSecondBarAbout .firstBarHalfAbout {
    width: auto;
  }

  .topQuadrantFirstBarAbout .secondBarHalfAbout img,
  .topQuadrantSecondBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }

  .offerStripAbout {
    height: auto;
  }

  .innerOfferStripAbout {
    flex-direction: column;
  }

  .innerOfferStripAbout img {
    width: 10px;
    height: 10px;
  }
  .innerOfferStripAbout h5 {
    margin-top: -36px;
    margin-left: 35px;
  }

  .innerOfferStripAbout button {
    width: auto;
    margin-left: auto;
  }

  .processContainerAbout h3 {
    font-size: 24px;
  }

  .processParaInAbout {
    font-size: 18px;
  }
  .processContainerAbout .bannerContainerAbout {
    width: auto;
  }
  .processContainerAbout .bannerContainerAbout {
    height: auto;
  }
  .innerBoxbannerContainerAbout {
    height: auto;
    margin-bottom: 25px;
  }

  .innerBoxbannerContainerAbout h4 {
    margin-top: 20px;
  }

  .innerBoxbannerContainerAbout img {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .diagramBannerContainer {
    display: flex;
    flex-direction: column;
  }

  .ourFeatuesAboutTagline {
    width: 87%;
  }

  .processContainerAbout .diagramContainerAbout {
    width: 100%;
    height: auto;
    margin-top: 50px;
    padding-right: 0%;
  }

  .ourFeatuesAbout {
    padding: 5%;
  }

  .ourFeatuesAboutFirstBar {
    display: flex;
    flex-direction: column-reverse;
  }
  .ourFeatuesAboutSecondBar {
    display: flex;
    flex-direction: column;
  }
  .ourFeatuesAboutFirstBar .secondBarHalfFeaturesAbout img {
    width: 100%;
    padding-left: 0px;
  }
  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout {
    width: auto;
  }

  .ourFeatuesAboutSecondBar .secondBarHalfFeaturesAbout img {
    width: 100%;
    padding-right: 0px;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout {
    width: auto;
  }

  /*  */
  .topQuadrantFirstBarAbout .firstBarHalfAbout h4 {
    font-size: 28px;
  }
  .topQuadrantFirstBarAbout .firstBarHalfAbout p,
  .topQuadrantSecondBarAbout .firstBarHalfAbout p {
    font-size: 20px;
  }
  .topQuadrantFirstBarAbout .firstBarHalfAbout,
  .topQuadrantSecondBarAbout .firstBarHalfAbout {
    padding: 20px 30px;
  }
  .blockDiagramContainerAbout p {
    font-size: 14px;
    margin: 20px auto;
  }
  .blockDiagramContainerAbout img {
    width: 40px;
  }
  .downArrowDiagramContainerAbout {
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .processParaInAbout,
  .processContainerAbout h3 {
    margin-left: 0px;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout p,
  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout p {
    font-size: 20px;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout h3,
  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout h3 {
    font-size: 26px;
  }
  .firstCapitalFeatureAbout {
    font-size: 55px;
  }
  .innerBoxbannerContainerAbout b {
    font-size: 43px;
  }
}

.Packages-root-container {
  font-family: "Arimo", sans-serif;
}
.topBannerPackages {
  background-color: #ffc93c;
  height: 350px;
  padding-left: 70px;
  padding-top: 50px;
}

.topBannerPackagesHeading {
  font-size: 43px;
}

.topBannerPackagesDescription {
  width: 55vw;
  font-size: 25px;
  font-weight: 300;
  line-height: 1.1;
  margin-top: 49px;
}

.offerBoardPackages {
  width: 270px;
  height: 320px;
  background-color: #ff7f00;
  position: absolute;
  z-index: 100;
  top: 175px;
  right: 10%;
  border-radius: 5px;
}

.innerBoxofferBoardPackages {
  width: 86%;
  height: 86%;
  margin-top: 7%;
  margin-left: 7%;
  border: 2px solid #ffffff;
}

.innerBoxofferBoardPackages h5 {
  font-size: 18px;
  margin-left: 8%;
  margin-top: 18px;
  color: #ffffff;
}

.innerBoxofferBoardPackages b {
  font-size: 30px;
}

.innerBoxofferBoardPackages button {
  display: block;
  position: absolute;
  right: 15%;
  bottom: 11%;
  height: 29px;
  font-size: 14px;
  padding: 2px 12px 5px;
  background-color: #14c245;
  border: none;
  border-radius: 0;
  box-shadow: 0px 3px 3px #00000045;
}

.imgWarranty {
  width: 205px;
  margin-left: 50px;
  margin-top: 5px;
}

.packageHeading {
  color: #c24914;
  display: inline-block;
  font-size: 32px;
  margin-left: 100px;
  margin-top: 90px;
  padding-bottom: 15px;
  border-bottom: 3px solid #c24914;
}

.offerPackDiv {
  border: solid 3px #c24914;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 70px;
}

.offerPackDivHeader {
  background-color: #c3aa8c;
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.offerPackDivHeader h2 {
  margin-left: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 50px;
  color: #fff5f5;
}

.offerPackDivHeader strike {
  color: red;
  text-decoration-thickness: 1px;
}

.offerPackDivHeader strike span {
  color: #000000;
}
.offerPackDivBody {
  display: flex;
  margin: 40px 40px 0px 40px;
}

.offerPackDivLeftColumn {
  width: 50%;
}

.offerPackDivLeftColumn p {
  margin: 20px;
}

.offerPackDivRightColumn p {
  margin: 20px;
}

.offerPackDivBottom {
  margin: 20px 40px 20px 40px;
}

.offerPackDivBottom p {
  margin: 30px 20px 0px 0px;
}

.Packages-detail-content-container p {
  font-size: 20px;
  text-align: justify;
  background-color: #faebd7;
  border: 2px solid #cab8a1;
  font-style: italic;
  border-radius: 5px;
}
.topBannerPackagesDescriptionAuthor {
  font-size: 20px !important;
}
.Package-more-offers-button-container button {
  height: 40px;
  font-size: 14px;
  padding: 2px 12px 5px;
  background-color: #14c245;
  border: none;
  border-radius: 0;
  margin-left: auto;
  border-radius: 5px;
}
.Package-more-offers-button-container a {
  text-decoration: none !important;
}

.Package-more-offers-button-container {
  display: flex;
  justify-content: right;
}
.offerPackDiv {
  background-color: #fff8dc;
  border: none;
  border-radius: 15px;
  padding-bottom: 20px;
}
.Packages-star-image-heading span {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 21px;
}
.Packages-star-image-heading div {
  display: flex;
}

.Packages-star-image-heading span img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}
.Packages-star-image-heading {
  display: flex;
  align-items: center;
}
.Packages-asterisk {
  color: #ff0000;
  margin-right: 2px;
}
@media (min-width: 1400px) {
  .topBannerPackages {
    height: auto;
    padding-bottom: 50px;
  }

  .offerPackDiv {
    width: 1400px;
    margin: 60px auto;
    background-color: #fff8dc;
  }
  .Packages-star-image-heading {
    width: 1400px;
    margin: 20px auto;
  }

  .imgWarranty {
    margin-left: 0px;
  }
}

.topBannerPackagesDescription b {
  font-style: italic;
  color: #575656;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .topBannerPackages {
    height: auto;
    padding-bottom: 70px;
  }
  .offerBoardPackages {
    top: 200px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .topBannerPackages {
    height: auto;
    padding-bottom: 50px;
  }
  .offerBoardPackages {
    top: 205px;
  }
  .offerStripGallery {
    height: auto !important;
    margin-right: 25px;
    margin-left: 75px;
    padding: 8px 15px 8px 14px !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .topBannerPackages {
    height: auto;
    padding-bottom: 40px;
  }
  .topBannerPackagesDescription {
    width: 43%;
    text-align: justify;
  }
  .offerStripGallery {
    height: auto !important;
    margin-right: 25px;
    margin-left: 75px;
    padding: 8px 15px 8px 14px !important;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 767.98px) and (min-width: 576px) {
  .offerBoardPackages {
    display: none;
  }
  .topBannerPackagesDescription {
    width: auto;
  }
  .topBannerPackages {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    height: auto;
  }
  .packageHeading {
    margin-left: 20px;
  }
  .galleryTopSection {
    display: flex;
    flex-direction: column;
  }
  .offerStripGallery {
    height: auto !important;
    margin-right: 25px;
    margin-left: 5px;
    margin-top: 10px;
    padding: 8px 15px 8px 14px !important;
  }
  .galleryHeading {
    margin: 40px 0px 40px 40px;
  }
  .offerStripGallery {
    margin-left: 15px;
  }
}

@media (max-width: 575.98px) {
  .offerBoardPackages {
    display: none;
  }

  .topBannerPackages {
    padding-left: 30px;
  }

  .topBannerPackages {
    background-color: #ffc93c;
    height: auto;
    padding-bottom: 20px;
  }

  .topBannerPackagesDescription {
    width: auto;
  }
  .packageHeading {
    margin-top: 20px;
  }

  .offerPackDiv {
    margin: 30px 30px 0px 30px;
  }
  .offerPackDivHeader h2 {
    margin-left: 10px;
  }
  .offerPackDivLeftColumn {
    width: auto;
  }
  .offerPackDivBody {
    margin: 20px 20px 0px 20px;
  }

  .imgWarranty {
    margin: 15px auto;
    display: block;
  }
  .Packages-star-image-heading span img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  .Packages-star-image-heading {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .Packages-star-image-heading > div > span > label {
    font-size: 14px;
    padding: 0px 14px;
  }
  .packageHeading {
    width: 40%;
    margin-left: 0px;
    margin-top: 30px;
  }
  .offerPackDivHeader {
    flex-direction: column;
  }
  .offerPackDivHeader h2:nth-child(1) {
    margin-left: 10px;
    padding-top: 23px;
    padding-bottom: 0px;
  }
}

.OurCapabilities-root-container {
  font-family: "Arimo", sans-serif;
}

.firstBarOurCapabilities {
  background-color: #ff6f3c;
}

.firstBarOurCapabilities .firstHalf {
  background-color: #ff6f3c;
}

.firstBarOurCapabilities .secondHalf img {
  width: 50vw;
  height: 350px;
}
.secondBarCapabilities .secondHalf img {
  width: 50vw;
  height: 350px;
}

.firstBarOurCapabilities .firstHalf span {
  margin-left: 85px;
  margin-top: 20px;
  margin-right: 45px;
  font-size: 24px;
  line-height: 1.1;
  color: #ffffff;

  font-weight: 300;
}

.firstBarOurCapabilities .firstHalf h4 {
  font-size: 42px;
  margin-top: 45px;
  margin-left: 85px;
  color: #ffffff;

  font-weight: 500;
}

.secondBarCapabilities .firstHalf span {
  color: #000;
  font-size: 24px;
  line-height: 1.1;
  margin-left: 85px;
  margin-top: 100px;
  margin-right: 45px;

  font-weight: 300;
}
.secondBarCapabilities .firstHalf {
  background-color: #ff9a3c;
}

.ourFeatuesCapabilitiesFirstBar {
  width: 100vw;
  padding: 50px 35px 10px 65px;
}

.ourFeatuesCapabilitiesFirstBar .firstBarHalfFeaturesCapabilities {
  width: 39vw;
}

.ourFeatuesCapabilitiesFirstBar .firstBarHalfFeaturesCapabilities h3 {
  font-size: 43px;
  color: #155263;

  font-weight: 500;
}

.ourFeatuesCapabilitiesFirstBar .firstBarHalfFeaturesCapabilities h5 {
  color: #000000;
  font-size: 30px;
  font-weight: 300;
}

.ourFeatuesCapabilitiesFirstBar .firstBarHalfFeaturesCapabilities p {
  color: #000000a9;
  font-size: 24px;
  font-weight: 300;

  margin-top: 20px;
  margin-right: 20px;
  line-height: 1.2;
}

.ourFeatuesCapabilitiesFirstBar .secondBarHalfFeaturesCapabilities img {
  width: 54vw;
  height: 375px;
}
.hrLineourCapabilitiesOne {
  border: 1px solid #ffc93c;
  margin-top: 15px;
  margin-left: 35px;
  width: 1210px;
}

.projectsYearsContainer .left .firstBarHalfFeaturesCapabilities {
  width: 47vw;
}

.projectsYearsContainer .left .firstBarHalfFeaturesCapabilities h3 {
  color: #155263;
  margin-left: 40px;
  font-size: 41px;

  font-weight: 500;
  margin-top: 30px;
}

.projectsYearsContainer .left .firstBarHalfFeaturesCapabilities h5 {
  color: #000000;
  font-size: 30px;
  font-weight: 300;
  margin-left: 40px;
}

.projectsYearsContainer .left .firstBarHalfFeaturesCapabilities p {
  color: #000000a9;
  font-size: 26px;
  font-weight: 300;

  margin-top: 30px;
  line-height: 1.2;
  margin-left: 40px;
}

.projectsYearsContainer .left .secondBarHalfFeaturesAbout img {
  width: 50vw;
  height: 375px;
  padding-right: 20px;
  padding-left: 20px;
}

.projectsYearsContainer {
  display: flex;
  margin-top: 30px;
  position: relative;
  width: 50vw;
}

.projectsYearsContainer .left {
  width: 50vw;
}

.projectsYearsContainer .right {
  width: 49vw;
}

.projectsYearsContainer .right .firstBarHalfFeaturesAbout {
  width: 46vw;
}

.projectsYearsContainer .right .firstBarHalfFeaturesCapabilities h3 {
  color: #155263;
  margin-left: 55px;
  font-size: 41px;

  font-weight: 500;
}

.projectsYearsContainer .right .firstBarHalfFeaturesCapabilities h5 {
  color: #000000;
  font-size: 30px;
  font-weight: 300;
  margin-left: 55px;
}

.projectsYearsContainer .right .firstBarHalfFeaturesCapabilities p {
  color: #000000a9;
  font-size: 26px;
  font-weight: 300;

  margin-top: 30px;
  line-height: 1;
  margin-left: 55px;
  margin-right: 20px;
}

.projectsYearsContainer .right .secondBarHalfFeaturesAbout img {
  width: 50vw;
  height: 375px;
  padding-right: 35px;
  padding-left: 40px;
  margin-top: 15px;
}

.projectsYearsContainer .center {
  width: 1%;
  height: 62vw;
}

.projectsYearsContainer .center hr {
  width: 2px;
  height: 63vw;
  border: 1px solid #ffc93c;
  position: absolute;
  top: -48px;
}

.hassleFreeContainer {
  margin-top: 60px;
  position: relative;
}

.hassleFreeContainer .firstSection .firstImage {
  width: 30vw;
}
.hassleFreeContainer .firstSection .secondImage {
  width: 30vw;
}
.hassleFreeContainer .firstSection .thirdImage {
  width: 30vw;
}

.hassleFreeContainer .firstSection .firstImage img {
  width: 30vw;
  height: 17vw;
}

.hassleFreeContainer .firstSection .firstImage p {
  font-size: 23px;
  font-weight: 300;

  color: #000;
  line-height: 1.3;
  margin-top: 30px;
  text-align: center;
}

.hassleFreeContainer .firstSection .secondImage p {
  font-size: 23px;
  font-weight: 300;

  color: #000;
  line-height: 1.3;
  margin-top: 30px;
  text-align: center;
}

.hassleFreeContainer .firstSection .thirdImage p {
  font-size: 23px;
  font-weight: 300;

  color: #000;
  line-height: 1.3;
  margin-top: 30px;
  text-align: center;
}

.hassleFreeContainer .firstSection .secondImage img {
  width: 30vw;
  height: 17vw;
}

.hassleFreeContainer .firstSection .thirdImage img {
  width: 30vw;
  height: 17vw;
}

.hassleFreeContainer .firstSection,
.thirdSection {
  display: flex;
  justify-content: space-evenly;
  margin-left: 2vw;
  margin-right: 2vw;
}

.hassleFreeContainer .thirdSection {
  height: 420px;
}

.hassleFreeContainer .thirdSection .firstImage {
  width: 46vw;
}
.hassleFreeContainer .thirdSection .secondImage {
  width: 46vw;
}

.hassleFreeContainer .thirdSection .firstImage img {
  width: 46vw;
  height: 24vw;
}

.hassleFreeContainer .thirdSection .secondImage img {
  width: 46vw;
  height: 24vw;
}

.hassleFreeContainer .thirdSection .center {
  width: 1vw;
  height: 62vw;
}

.hassleFreeContainer .thirdSection .center hr {
  width: 2px;
  height: 26vw;
  border: 1px solid #ffc93c;
  position: absolute;
  top: 350px;
  left: 640px;
}

.hassleFreeContainer p {
  color: #000000;
  font-size: 24px;
  font-weight: 300;

  margin-top: 20px;
  line-height: 1;
  letter-spacing: 0px;
  text-align: center;
}

.hassleFreeTagline {
  color: #ff6f3c;

  font-weight: 500;
  font-size: 32px;
  font-style: italic;
  letter-spacing: 0px;
  text-align: center;
}

@media (min-width: 1400px) {
  .ourFeatuesCapabilitiesFirstBar {
    width: 1400px;
    padding: 50px 35px 10px 65px;
    margin: 0px auto;
  }
  .ourFeatuesCapabilitiesFirstBar .secondBarHalfFeaturesCapabilities img {
    width: 100%;
  }
  .ourFeatuesCapabilitiesFirstBar .firstBarHalfFeaturesCapabilities {
    width: 60%;
  }
  .secondBarHalfFeaturesCapabilities {
    width: 40%;
  }
  .hrLineourCapabilitiesOne {
    width: auto;
  }
  .projectsYearsContainer {
    width: 1400px;
    margin: 30px auto;
  }
  .projectsYearsContainer .left {
    width: 49%;
  }
  .projectsYearsContainer .left .secondBarHalfFeaturesAbout img {
    width: 100%;
  }
  .projectsYearsContainer .right {
    width: 49%;
  }
  .projectsYearsContainer .right .secondBarHalfFeaturesAbout img {
    width: 100%;
  }
  .ourcapabilities-image-container {
    width: 100%;
  }
}

.firstBarOurCapabilities i {
  color: #ffd700;
}

@media (min-width: 1400px) {
  .projectsYearsContainer .left .firstBarHalfFeaturesCapabilities {
    width: 88%;
  }
  .projectsYearsContainer .center {
    width: 1vw;
    height: 900px !important;
  }
  .projectsYearsContainer .center hr {
    height: 900px;
  }
  .hassleFreeContainer .firstSection .firstImage img,
  .hassleFreeContainer .firstSection .secondImage img,
  .hassleFreeContainer .firstSection .thirdImage img {
    width: 96%;
    height: 300px;
    object-fit: cover;
  }
  .hassleFreeContainer {
    width: 1400px;
    margin: 0px auto;
  }
  .hassleFreeContainer .thirdSection .firstImage img,
  .hassleFreeContainer .thirdSection .secondImage img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .hassleFreeContainer .thirdSection .center hr {
    width: 2px;
    height: 500px;
    border: 1px solid #ffc93c;
    position: absolute;
    top: auto;
    left: auto;
    margin: 3px;
  }
  .hassleFreeContainer .thirdSection .center {
    width: 1%;
    height: auto;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .hrLineourCapabilitiesOne {
    width: 95%;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .hrLineourCapabilitiesOne {
    width: 95%;
  }
  .hassleFreeContainer .thirdSection .center hr {
    position: static;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .hrLineourCapabilitiesOne {
    width: 95%;
  }
  .hassleFreeContainer .thirdSection .center hr {
    position: static;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 767.98px) and (min-width: 576px) {
  .firstBarOurCapabilities .firstHalf h4 {
    font-size: 33px;
    margin-top: 25px;
    margin-left: 25px;
  }
  .firstBarOurCapabilities .firstHalf span {
    margin-left: 25px;
    font-size: 20px;
  }
  .secondBarCapabilities .firstHalf span {
    font-size: 20px;
    margin-left: 35px;
  }
  .projectsYearsContainer .right .firstBarHalfFeaturesCapabilities h3,
  .projectsYearsContainer .left .firstBarHalfFeaturesCapabilities h3 {
    margin-left: 25px;
    font-size: 33px;
  }
  .projectsYearsContainer .right .firstBarHalfFeaturesCapabilities h5,
  .projectsYearsContainer .left .firstBarHalfFeaturesCapabilities h5 {
    margin-left: 25px;
    font-size: 22px;
  }

  .projectsYearsContainer .right .firstBarHalfFeaturesCapabilities p,
  .projectsYearsContainer .left .firstBarHalfFeaturesCapabilities p {
    margin-top: 20px;
    margin-left: 25px;
    font-size: 22px;
  }
  .projectsYearsContainer .center hr {
    height: 730px;
  }
  .hrLineourCapabilitiesOne {
    width: 95%;
  }
  .ourFeatuesCapabilitiesFirstBar {
    width: 100vw;
    padding: 40px 35px 10px 35px;
  }
  .ourFeatuesCapabilitiesFirstBar .secondBarHalfFeaturesCapabilities img {
    width: 50vw;
  }
  .ourFeatuesCapabilitiesFirstBar .firstBarHalfFeaturesCapabilities p {
    font-size: 22px;
  }
  .ourFeatuesCapabilitiesFirstBar .firstBarHalfFeaturesCapabilities h5 {
    font-size: 24px;
  }
  .ourFeatuesCapabilitiesFirstBar .firstBarHalfFeaturesCapabilities h3 {
    font-size: 33px;
  }
  .hassleFreeTagline {
    font-size: 26px;
    margin-top: 24px;
  }
  .hassleFreeContainer {
    margin-top: 30px;
  }
  .hassleFreeContainer .firstSection .firstImage p,
  .hassleFreeContainer .firstSection .secondImage p,
  .hassleFreeContainer .firstSection .thirdImage p {
    font-size: 18px;
    margin-top: 20px;
    text-align: left;
  }
  .hassleFreeContainer p {
    font-size: 18px;
    margin-top: 20px;
    text-align: left;
  }
  .hassleFreeContainer .thirdSection .center hr {
    height: 38vw;
    top: 270px;
    left: auto;
  }
  .hassleFreeContainer .thirdSection {
    height: 320px;
  }
}

@media (max-width: 575.98px) {
  .firstBarOurCapabilities {
    display: flex;
    flex-direction: column;
  }

  .firstBarOurCapabilities .firstHalf {
    width: auto;
  }

  .firstBarOurCapabilities .firstHalf h4 {
    margin-top: 35px;
    margin-left: 35px;
  }
  .firstBarOurCapabilities .firstHalf span {
    margin-left: 35px;
    margin-bottom: 30px;
  }
  .firstBarOurCapabilities .secondHalf img {
    width: 100vw;
  }

  .secondBarCapabilities {
    display: flex;
    flex-direction: column-reverse;
  }

  .secondBarCapabilities .secondHalf {
    width: auto;
  }
  .secondBarCapabilities .secondHalf img {
    width: 100vw;
  }
  .secondBarCapabilities .firstHalf span {
    margin-left: 35px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .ourFeatuesCapabilitiesFirstBar {
    width: auto;
    padding: 40px 35px 10px 25px;
    flex-direction: column;
  }
  .secondBarCapabilities .firstHalf {
    width: auto;
  }
  .ourFeatuesCapabilitiesFirstBar .firstBarHalfFeaturesCapabilities {
    width: auto;
  }
  .ourFeatuesCapabilitiesFirstBar .secondBarHalfFeaturesCapabilities img {
    width: 90vw;
  }
  .hrLineourCapabilitiesOne {
    margin-left: 25px;
    width: 90vw;
  }
  .projectsYearsContainer .center {
    display: none;
  }
  .projectsYearsContainer {
    display: flex;
    width: 100vw;
    flex-direction: column;
  }
  .projectsYearsContainer .left .secondBarHalfFeaturesAbout img {
    width: 98vw;
    padding-right: 20px;
    padding-left: 26px;
  }
  .projectsYearsContainer .left .firstBarHalfFeaturesCapabilities {
    width: 90vw;
  }
  .projectsYearsContainer .right {
    width: 90vw;
    display: flex;
    flex-direction: column-reverse;
  }
  .projectsYearsContainer .right .firstBarHalfFeaturesCapabilities h3,
  .projectsYearsContainer .right .firstBarHalfFeaturesCapabilities h5,
  .projectsYearsContainer .right .firstBarHalfFeaturesCapabilities p {
    margin-left: 45px;
  }
  .projectsYearsContainer .right .secondBarHalfFeaturesAbout img {
    width: 98vw;
    padding-right: 20px;
    padding-left: 26px;
  }
  .projectsYearsContainer .right .firstBarHalfFeaturesCapabilities h3 {
    margin-top: 30px;
  }

  .hassleFreeTagline {
    margin: 40px 15px 10px;
  }

  .firstSection {
    display: flex;
    flex-direction: column;
  }
  .hassleFreeContainer .firstSection .firstImage img,
  .hassleFreeContainer .firstSection .secondImage img,
  .hassleFreeContainer .firstSection .thirdImage img {
    width: 96vw;
    height: auto;
  }

  .hassleFreeContainer .firstSection .firstImage,
  .hassleFreeContainer .firstSection .secondImage,
  .hassleFreeContainer .firstSection .thirdImage {
    width: 90vw;
  }

  .hassleFreeContainer .thirdSection {
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .hassleFreeContainer .thirdSection .center {
    display: none;
  }

  .hassleFreeContainer .thirdSection .firstImage,
  .hassleFreeContainer .thirdSection .secondImage {
    width: auto;
  }

  .hassleFreeContainer .thirdSection .firstImage img,
  .hassleFreeContainer .thirdSection .secondImage img {
    width: 96vw;
    height: auto;
  }
  .hassleFreeContainer {
    margin-top: 30px;
  }
}

.Projects-root-container {
  font-family: "Arimo", sans-serif;
}

.topBannerProjects {
  background-color: #ea5455;
  height: 410px;
  padding-left: 70px;
  padding-top: 50px;
  color: #ffffff;
}
.topBannerProjectsHeading {
  font-size: 43px;
}
.topBannerProjectsDescription {
  width: 40vw;
  font-size: 18px;

  font-weight: 300;
  line-height: 1.1;
  margin-top: 25px;
  padding-right: 20px;
}

.ourWorksImageContainer {
  width: 53vw;
  height: 340px;
  background-color: #ff7f00;
  position: absolute;
  z-index: 100;
  top: 200px;
  right: 0;
}

.ourWorksImageContainer img {
  width: 53vw;
  height: 340px;
}

.projectListItemsContainer {
  padding: 50px 15px 110px 70px;
}

.projectListItemsContainer .listItemOne {
  display: flex;
}

.projectListItemsContainer .listItemOne {
  display: flex;
  position: relative;
}

.projectListItemsContainer .listItemOne .right .imageOne {
  width: 500px;
  height: 350px;
}

.projectListItemsContainer .listItemOne .right .imageTwo {
  width: 315px;
  height: 195px;
  position: absolute;
  top: 155px;
  right: 0;
}

.projectListItemsContainer .listItemOne .right .imageThree {
  width: 315px;
  height: 145px;
  position: absolute;
  top: 0;
  right: 0;
}

.projectListItemsContainer .listItemOne .left {
  width: 372px;
}

.listItemOne .left h3 {
  font-size: 30px;
  color: #f07b3f;
}

.listItemOne .left h6 {
  font-size: 25px;
  color: #000000;

  font-weight: 300;
  margin-top: 20px;
}

.listItemOne .left p {
  font-size: 16px;
  color: #000000c0;

  font-weight: 300;
  margin-top: 28px;
  padding-right: 50px;
}

.secondItem,
.thirdItem {
  margin-top: 30px;
}

.topBannerProjects i {
  color: #ffd700;
}
@media (min-width: 1400px) {
  .ourWorksImageContainer {
    width: 1400px;
    height: 300px;
    top: 320px;
    position: static;
    max-width: 1400px;
    margin: 0px auto;
  }
  .ourWorksImageContainer img {
    width: 1400px;
    height: 300px;
    object-fit: cover;
  }
  .projectListItemsContainer {
    max-width: 1400px;
    margin: 0px auto;
  }
  .topBannerProjects {
    height: 350px;
    max-width: 1400px;
    margin: 0px auto;
    padding-right: 50px;
    text-align: justify;
  }

  .topBannerProjectsDescription {
    width: auto;
  }
}

.Projects-testimonial-replacement-container {
  display: flex;
  justify-content: center;
  margin: 20px auto;
}
.Projects-testimonial-replacement-container img {
  width: 100%;
  object-fit: cover;
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .topBannerProjects {
    height: 490px;
  }
  .topBannerProjectsDescription {
    width: 49%;
  }
  .ourWorksImageContainer {
    width: 45%;
    height: 300px;
    top: 320px;
  }
  .ourWorksImageContainer img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .topBannerProjects {
    height: 490px;
  }
  .topBannerProjectsDescription {
    width: 49%;
  }
  .ourWorksImageContainer {
    width: 45%;
    height: 300px;
    top: 320px;
  }
  .ourWorksImageContainer img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .topBannerProjects {
    background-color: #ea5455;
    height: 410px;
    padding-left: 70px;
    padding-top: 50px;
    padding-right: 50px;
    color: #ffffff;
  }
  .topBannerProjectsDescription {
    width: auto;
  }

  .ourWorksImageContainer img {
    width: 100%;
    height: 340px;
    object-fit: cover;
  }
  .ourWorksImageContainer {
    display: block;
    position: static;
    width: 100%;
    height: 340px;
    background-color: #ff7f00;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .ourWorksImageContainer img {
    width: 100%;
    height: 340px;
    object-fit: cover;
  }
  .ourWorksImageContainer {
    display: block;
    position: static;
    width: 100%;
    height: 340px;
    background-color: #ff7f00;
  }
  .topBannerProjects {
    padding-left: 30px;
    padding-bottom: 45px;
    padding-top: 30px;
    padding-bottom: 45px;
    height: auto;
  }
  .topBannerProjectsDescription {
    width: auto;
    font-size: 22px;
    margin-top: 29px;
  }

  .topBannerProjectsHeading {
    font-size: 33px;
  }
  .projectListItemsContainer .listItemOne {
    flex-direction: column;
  }
  .projectListItemsContainer .listItemOne .right .imageOne {
    width: 340px;
    height: 300px;
  }
  .projectListItemsContainer .listItemOne .right .imageTwo {
    height: 148px;
    top: 240px;
  }
  .projectListItemsContainer .listItemOne .right .imageThree {
    top: 390px;
    height: 150px;
  }
  .projectListItemsContainer {
    padding: 50px 15px 110px 40px;
  }
  .projectListItemsContainer .listItemOne .left {
    width: auto;
  }
  .listItemOne .left h3 {
    font-size: 28px;
  }
  .Projects-testimonial-replacement-container img {
    width: 100%;
    object-fit: cover;
    max-width: 1400px;
  }
}

@media (max-width: 575.98px) {
  .topBannerProjects {
    height: auto;
    padding-left: 30px;
    padding-top: 25px;
  }
  .ourWorksImageContainer {
    position: static;
  }
  .topBannerProjectsDescription {
    width: auto;
    margin-top: 20px;
    padding-bottom: 50px;
  }
  .ourWorksImageContainer img {
    width: 100vw;
  }
  .projectListItemsContainer {
    padding: 50px 15px 30px 30px;
  }
  .projectListItemsContainer .listItemOne {
    flex-direction: column;
  }
  .projectListItemsContainer .listItemOne .right .imageOne {
    width: 55vw;
    height: 200px;
  }
  .projectListItemsContainer .listItemOne .right .imageTwo {
    width: 35vw;
    height: 105px;
    top: 270px;
  }
  .projectListItemsContainer .listItemOne .right .imageThree {
    width: 35vw;
    height: 93px;
    top: 377px;
  }

  .projectListItemsContainer .listItemOne .left {
    width: auto;
  }

  .Projects-testimonial-replacement-container img {
    width: 100%;
    object-fit: cover;
    max-width: 1400px;
  }
}

.Gallery-root-container {
  font-family: "Arimo", sans-serif;
}

.galleryTopSection {
  display: flex;
}

.galleryHeading {
  font-size: 46px;
  color: #000000;
  margin: 40px 0px 40px 80px;
  font-weight: 500;
}

.offerStripGallery {
  height: 105px;
  margin-right: 25px;
  margin-left: 75px;
  padding: 20px 15px 5px 25px;
}

.offerStripGallery .innerOfferStripAbout {
  padding: 20px 5px 10px 0px;
  margin-left: 5px;
  margin-right: 0px;
  justify-content: center;
}

.offerStripGallery h5 {
  font-size: 19px;
  margin-right: 20px;
}
.offerStripGallery button {
  margin-left: 40px;
  font-size: 14px;
}

.offerStripGallery img {
  width: 8px;
  height: 8px;
}

.galleryImageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 96%;
  margin: 0px auto 60px auto;
}

.galleryImageContainer img {
  width: 32.8%;
  height: 350px;
  object-fit: cover;
  margin-bottom: 10px;
}

.Gallery-outer-shadow-container {
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  z-index: 5;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.Gallery-outer-shadow-container div {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.Gallery-outer-shadow-container span {
  background-color: #ffffff;
  border: 2px solid #7c731c;
  color: #7c731c;
  font-size: 24px;
  display: inline-block;
  margin: 40px auto 10px auto;
  font-size: 18px;
  padding: 10px 30px;
  cursor: pointer;
}

.Gallery-outer-shadow-container img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
  margin: 20px auto 10px auto;
  border: 2px solid #7c731c;
  background-color: #ffffff;
}
.innerOfferStripAbout span {
  width: 4px;
  height: 35px;
  margin-top: -4px;
  margin-left: 9px;
  background-color: #bfbfbf;
}

.Gallery-root-container {
  max-width: 1400px;
  margin: 0px auto;
}
@media (min-width: 1400px) {
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .Gallery-root-container {
    margin-top: 30px;
  }
  .offerStripGallery {
    margin-left: 30px;
    padding: 20px 15px 5px 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .Gallery-root-container {
    margin-top: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .Gallery-root-container {
    margin-top: 30px;
  }
  .offerStripGallery {
    margin-left: auto;
    margin-right: auto;
  }
  .galleryTopSection {
    display: flex;
    flex-direction: column;
  }
  .galleryHeading {
    margin: 20px 0px 20px 30px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .offerStripGallery {
    margin-left: auto;
    margin-right: auto;
  }
  .galleryHeading {
    margin: 40px 0px 20px 30px;
  }
  .galleryTopSection {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 575.98px) {
  .galleryImageContainer img {
    width: 96vw;
  }
  .offerStripGallery {
    height: auto;
    margin-right: 25px;
    margin-left: 25px;
    margin-top: 10px;
    width: 90vw;
    padding: 15px;
  }
  .galleryTopSection {
    display: flex;
    flex-direction: column;
  }
  .galleryHeading {
    margin: 40px 0px 20px 20px;
  }
  .offerStripGallery button {
    margin-left: auto;
  }
}

.Careers-root-container {
  font-family: "Arimo", sans-serif;
}
.careerHeading {
  font-size: 2.5rem;
  color: #000000b0;
  margin: 50px 0px 40px 80px;

  font-weight: 500;
}

.careerBar {
  background-color: #155263;
  display: flex;
  height: 600px;
}

.careerBar img {
  width: 53vw;
  object-fit: cover;
}

.careerBarTextContent {
  font-size: 1.5rem;
  width: auto;
  margin: 30px 50px 50px 65px;
  color: #ffffff;
}

.careerBarTextTagLine {
  margin: 40px 50px 0px 65px;
  font-size: 2rem;
  color: #ffffff;
  display: block;
}
.careerBarTextMailId {
  margin: 0px 50px 0px 65px;
  font-size: 3rem;
  color: #ffffff;
  display: block;
  position: relative;
  bottom: 15px;
}

.careerBarTextMailIdUnderLine {
  border: 1px solid #ff6f3c;
  width: 195px;
  height: 10px;
  margin: 0px 0px 0px 70px;
}

.currentJobOpeningLine {
  border: 1px solid #ff6f3c;
  margin-top: 100px;
}

.currentJobOpeningHeading {
  color: #000000b4;
  font-size: 34px;
  margin: 40px 0px 30px 80px;

  font-weight: 500;
}

.currentJobOpeningList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 40px 0px 70px 50px;
}

.currentJobOpeningList li {
  margin-right: 100px;

  font-weight: 400;
  font-size: 24px;
  color: #000000b4;
  padding-bottom: 20px;
}

.currentJobOpeningList li img {
  margin-right: 20px;
  width: 16px;
}

.cardContainer {
  background-color: #ffffff !important;
}
.Contact-complete-name img {
  height: auto !important;
  width: 500px !important;
  display: block;
  margin: 20px auto 0px auto;
}

@media (max-width: 1199.98px) and (min-width: 992px) {
  .careerBar img {
    width: 43%;
    object-fit: cover;
  }
  .careerBarTextContent {
    font-size: 20px;
    width: auto;
    margin: 30px 50px 50px 60px;
    color: #ffffff;
  }
}

@media (max-width: 991.98px) and (min-width: 768px) {
  .careerBar {
    background-color: #155263;
    display: flex;
    height: auto;
    flex-direction: column;
    padding-bottom: 30px;
  }
  .careerBar img {
    width: 70%;
    object-fit: cover;
    margin: 20px auto 0px auto;
  }
  .Contact-complete-name img {
    height: auto !important;
    width: 500px !important;
    display: block;
    margin: 20px auto 0px auto;
  }
  .cardContainer {
    background-color: #ffffff !important;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 767.98px) and (min-width: 576px) {
  .careerHeading {
    margin: 30px 0px 40px 40px;
  }
  .careerBar {
    height: auto;
    flex-direction: column;
  }
  .careerBar img {
    width: auto;
    object-fit: cover;
  }
  .careerBarTextContent {
    font-size: 1.2rem;
    width: 300px;
    margin: 65px 50px 50px 65px;
  }
  .currentJobOpeningLine {
    display: none;
  }
  .currentJobOpeningHeading {
    font-size: 28px;
  }
  .currentJobOpeningList li {
    font-size: 22px;
  }
  .careerBar {
    background-color: #155263;
    display: flex;
    height: auto;
    flex-direction: column;
  }
  .Contact-complete-name img {
    height: auto !important;
    width: 500px !important;
    display: block;
    margin: 20px auto 0px auto;
  }
}

@media (max-width: 575.98px) {
  .careerBar {
    display: flex;
    flex-direction: column;
    height: auto;
    padding-bottom: 100px;
  }
  .careerBar img {
    width: 100vw;
    height: auto;
  }
  .careerBarTextContent {
    margin: 65px 50px 50px 65px;
  }
  .currentJobOpeningLine {
    margin-top: 40px;
  }
  .careerHeading {
    margin: 50px 0px 40px 20px;
  }
  .careerBarTextMailId {
    margin: 0px 50px 0px 12px;
    font-size: 28px;
    margin-top: 15px;
  }
  .careerBarTextContent {
    margin: 30px 10px 10px 10px;
  }
  .careerBarTextTagLine {
    margin: 40px 50px 0px 10px;
    font-size: 24px;
  }
  .careerBarTextMailIdUnderLine {
    width: 117px;
    margin: 0px 0px 0px 14px;
  }
  .currentJobOpeningHeading {
    font-size: 30px;
    margin: 40px 0px 30px 20px;
  }
  .currentJobOpeningList {
    margin: 20px 0px 70px 10px;
  }
  .currentJobOpeningList li {
    margin-right: 0px;
  }
  .Contact-complete-name img {
    height: auto !important;
    width: 280px !important;
    display: block;
    margin: 20px auto 0px auto;
  }
  .header {
    padding: 25px 0px 0px 0px !important;
  }
  .generalEnquiryText {
    width: auto;
    font-size: 20px !important;
  }
}

.Contact-root-container {
  font-family: "Arimo", sans-serif;
}

.addressCardContainer {
  margin: 0px 0px 50px 0px;
}

.generalEnquiryText {
  color: #153e90;
  width: 700px;
  font-size: 24px;

  font-weight: 300;
  margin: 0 auto;
  padding-bottom: 15px;
  text-align: center;
}

.enquirySectionContainer {
  position: relative;
  height: 845px;
}

.typingFieldContainer {
  position: absolute;
  background-color: #ffffff;
  padding: 20px 50px 20px 50px;
  width: 600px;
  height: 735px;
  top: 70px;
  right: 100px;
}

::-webkit-input-placeholder {
  color: #0000008e;
  font-size: 1rem;
}

:-ms-input-placeholder {
  color: #0000008e;
  font-size: 1rem;
}

::placeholder {
  color: #0000008e;
  font-size: 1rem;
}

.typingFieldContainer input[type="text"],
.typingFieldContainer input[type="email"],
textarea {
  width: 100%;
  margin-top: 30px;
  background-color: #00000010;
  border-width: 0px;
  padding: 10px 0px 10px 30px;
  font-size: 1.5rem;
}

.typingFieldContainer input[type="submit"] {
  background-color: #153e90;
  color: #ffffff;
  margin-top: 25px;
  width: 30%;
  border-width: 0px;
  padding: 10px 0px 10px 10px;
  font-size: 1.5rem;
  display: block;
  margin-left: auto;
}

.typingFieldContainer h5 {
  font-size: 2rem;
  color: #0e49b5;

  font-weight: 500;
  margin-top: 20px;
}

.imageEnquiry {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.contactUsTopPortionText {
  background-color: #153e90;
  color: #ffffff;
  height: 420px;
}

.contactUsTopPortionText .firstBarHalfAbout p,
.contactUsTopPortionText .firstBarHalfAbout h4 {
  color: #ffffff;
}

.contactUsTopPortionText .firstBarHalfAbout p {
  margin-top: 55px;
}

.contactUsTopPortionText .firstBarHalfAbout {
  padding: 50px 42px 25px 80px;
}

.contactUsTopPortionText .secondBarHalfAbout img {
  height: 420px;
  object-fit: cover;
}

.enquiryStatusMessage {
  position: absolute;
}
.statusSuccess {
  background-color: white;
  color: green;
  font-size: 30px;
}

.statusFailure {
  color: red;
  font-size: 20px;
  position: absolute;
}
.ContactUs-number-social-media-container > label {
  font-size: 22px;
}
.ContactUs-number-social-media-container > label > span {
  font-size: 22px;
  margin-left: 15px;
}

.ContactUs-number-social-media-container > p {
  font-size: 22px;
}

.Contact-complete-name img {
  height: 120px;
  display: block;
  margin: 50px auto 0px auto;
}
.Contact-red-phone-number {
  color: #ff0000;
}
@media (min-width: 1400px) {
  .cardContainer {
    width: 30% !important;
  }
  .header h5 {
    font-size: 23px !important;
  }
  .body .address {
    font-size: 20px;
    padding: 20px 15px 10px 15px;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .cardContainer {
    width: 30% !important;
  }
  .header h5 {
    font-size: 23px !important;
  }
  .body .address {
    font-size: 20px;
    padding: 20px 15px 10px 15px;
  }
}

@media (min-width: 992px) and (max-width: 1119.98px) {
  .cardContainer {
    background-color: #fffaa4;
    width: 470px;
  }
  .firstRow {
    padding: 5px 20px 0px 20px;
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .header h5 {
    font-size: 24px !important;
  }
  .body .address {
    font-size: 18px !important;
    padding: 20px 10px 10px 10px !important;
  }
  .body .phone {
    font-size: 18px !important;
    padding: 0px 10px 12px 10px !important;
  }
  .cardContainer {
    background-color: #fffaa4;
    width: 460px !important;
  }
  .firstRow {
    padding: 5px 20px 0px 20px;
    flex-direction: column;
    align-items: center;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 767.98px) and (min-width: 576px) {
  .contactUsTopPortionText .secondBarHalfAbout img {
    display: none;
  }
  .contactUsTopPortionText {
    height: auto;
  }
  .contactUsTopPortionText .firstBarHalfAbout p {
    margin-top: 20px;
  }
  .topQuadrantFirstBarAbout .firstBarHalfAbout {
    width: auto;
  }
  .contactUsTopPortionText .firstBarHalfAbout {
    padding: 30px 42px 25px 40px;
  }
  .header h5 {
    font-size: 20px;
  }
  .cardContainer {
    width: auto;
  }

  .body p {
    font-size: 18px !important;
  }

  .locationBlock {
    font-size: 18px;
  }
  .locationUnderline {
    width: 130px;
  }
  .header h5 {
    font-size: 24px !important;
  }
  .body .address {
    font-size: 18px !important;
    padding: 20px 10px 10px 10px !important;
  }
  .body .phone {
    font-size: 18px !important;
    padding: 0px 10px 12px 10px !important;
  }
  .locationBlock {
    width: auto !important;
    margin: 20px 0px 5px 10px !important;
  }
  .locationUnderline {
    width: 170px !important;
    margin: 0px 0px 45px 65px !important;
  }
  .ContactUs-follow-icon img {
    width: 30px;
  }
  .ContactUs-number-social-media-container > p {
    font-size: 18px;
  }
  .ContactUs-follow-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ContactUs-number-social-media-container > label > span {
    display: block;
  }
  .generalEnquiryText {
    width: auto;
  }
  .firstRow {
    padding: 5px 20px 0px 20px;
    flex-direction: column;
    align-items: center;
  }
  .cardContainer .body {
    padding: 10px 0px 0px 20px;
  }
  .firstRow {
    padding: 5px 20px 0px 20px;
    flex-direction: column;
  }

  .typingFieldContainer {
    right: 5%;
  }
  .cardContainer {
    margin-top: 20px;
  }
}

@media (max-width: 575.98px) {
  .contactUsTopPortionText .firstBarHalfAbout {
    padding: 50px 42px 25px 30px;
  }
  .contactUsTopPortionText .firstBarHalfAbout p {
    margin-top: 25px;
  }
  .typingFieldContainer {
    width: 90%;
    height: auto;
    top: 0px;
    left: 5%;
    margin: 30px auto;
  }
  .contactUsTopPortionText .secondBarHalfAbout img {
    height: auto;
  }
  .enquirySectionContainer {
    height: 800px;
  }

  .addressCardContainer {
    margin: 0px 0px 10px 0px !important;
  }
  .contactUsTopPortionText {
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .generalEnquiryText {
    width: auto;
  }
  .header h5 {
    font-size: 24px !important;
  }
  .body .address {
    font-size: 18px !important;
    padding: 20px 10px 10px 10px !important;
  }
  .body .phone {
    font-size: 18px !important;
    padding: 0px 10px 12px 10px !important;
  }
  .locationBlock {
    width: auto !important;
    margin: 20px 0px 5px 10px !important;
    font-size: 22px;
  }
  .locationUnderline {
    width: 170px !important;
    margin: 0px 0px 45px 65px !important;
  }

  .ContactUs-follow-icon img {
    width: 30px;
  }
  .ContactUs-number-social-media-container > p {
    font-size: 18px;
  }
  .ContactUs-follow-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ContactUs-number-social-media-container > label > span {
    display: block;
  }
  .generalEnquiryText {
    width: auto;
  }
  .cardContainer {
    margin-top: 20px;
  }
  .typingFieldContainer {
    padding: 20px 10px 20px 10px;
  }
  .typingFieldContainer input[type="text"],
  .typingFieldContainer input[type="email"],
  textarea {
    padding: 10px 0px 10px 10px;
    font-size: 16px !important;
  }
  .typingFieldContainer h5 {
    font-size: 24px;
    margin-top: 10px;
  }
  .typingFieldContainer input[type="submit"] {
    margin-top: 25px;
    width: 40%;
    padding: 5px 10px;
    font-size: 18px;
  }
}

.cardContainer {
  font-family: "Arimo", sans-serif;
}
.cardContainer {
  width: 600px;
}

.header {
  padding: 25px 0px 0px 25px;
}

.header h5 {
  color: #153e90;
  font-size: 32px;

  font-weight: 400;
}

.body .address {
  color: #000000;
  font-size: 22px;

  font-weight: 400;
  padding: 2px 20px 10px 22px;
}

.body .phone {
  color: #000000;
  font-size: 22px;

  font-weight: 400;
  padding: 0px 20px 20px 21px;
}

.locationBlock {
  display: block;
  width: 300px;
  margin: 20px 0px 5px 24px;
  font-size: 24px;

  font-weight: 400;
  text-decoration: none;
  color: #000000;
}

.locationBlock img {
  width: 28px;
  margin-right: 20px;
}

.firstRow {
  display: flex;
  padding: 30px 0px 0px 0px;
  justify-content: space-evenly;
}

.locationUnderline {
  border: 1px solid #000000;
  width: 175px;
  height: 10px;
  margin: 0px 0px 45px 90px;
}

@media (max-width: 1279.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
}

@media (max-width: 575.98px) {
  .cardContainer {
    width: auto;
    margin-bottom: 20px;
  }
  .firstRow {
    padding: 5px 20px 0px 20px;
    flex-direction: column;
  }
}

.patternLogoContainerImages {
  width: 45%;
  height: 250px;
  margin: 0px auto 20px;
}

.patternLogoContainerImages div {
  margin-left: 100px;
}

#icPattern {
  width: 195px;
  height: 250px;
}

#icAstiea {
  height: 150px;
  width: 243px;
}

.fromText {
  margin: 35px auto 0px;
  font-size: 34px;
}

.bottomRectangle {
  background-color: #ffd700;
  padding: 20px 0% 20px 8%;
}

.Footer-copyright-container {
  background-color: #ffd700;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.bottomRectanglefirstColumn p {
  font-size: 24px;
  font-family: "Arimo", sans-serif;
  color: #000000b0;
  margin-top: 20px;
}

.bottomRectangleSecondColumn {
  text-align: center;
  padding-top: 25px;
}

.bottomRectangleSecondColumn img {
  width: 40px;
  margin-left: 25px;
}

.bottomRectangleSecondColumn p,
.followText {
  font-weight: 500;
  color: #000000b0;
  font-family: "Arimo", sans-serif;
}

.bottomRectangleThirdColumn {
  padding-top: 20px;
  margin-left: 40px;
}

.bottomRectangleThirdColumn li {
  display: inline;
  margin: 0px 8px;
  font-weight: 500;
  color: #000000b0;
  font-family: "Arimo", sans-serif;
}

.bottomRectangleThirdColumn ul {
  margin-bottom: 2px;
}

.noDecoration {
  text-decoration: none !important;
  color: #000;
  font-size: 14px;
}

#second_ul {
  display: inline-flex;
  flex-wrap: wrap;
  width: 400px;
}

.patternLogoContainerImages div {
  display: none !important;
}

#getEstimateButtonBottom {
  border-radius: 5px;
  background-color: #ff7f00;
  border: 1px solid #e57200;
}

#getEstimateButtonBottom:focus {
  border-radius: 5px;
}

@media (min-width: 1400px) {
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  #second_ul {
    width: auto;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .bottomRectangleThirdColumn ul {
    margin-bottom: 2px;
    flex-direction: column;
    display: flex;
  }

  #second_ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 200px;
  }

  .whyToChooseContent {
    padding: 0px 5%;
    width: 99%;
  }

  .prevIconContainer {
    background-color: #ffd700;
    width: 40px;
    height: 50px;
    z-index: -1;
  }
}

@media (max-width: 767.98px) and (min-width: 576px) {
  #icPattern {
    width: auto;
    height: 210px;
  }

  .patternLogoContainerImages div {
    margin-left: 30px;
  }
  #icAstiea {
    height: 120px;
    width: auto;
  }
  .bottomRectangle {
    padding: 20px 0% 20px 0%;
  }
  .bottomRectanglefirstColumn p {
    font-size: 20px;
  }
  .bottomRectangleThirdColumn {
    margin-left: 10px;
    padding-top: 20px;
  }
  .bottomRectangleThirdColumn ul {
    display: inline-flex;
    flex-wrap: wrap;
  }

  #second_ul {
    display: inline-flex;
    flex-wrap: wrap;
    width: 200px;
  }
  .bottomRectangleSecondColumn img {
    width: 30px;
    margin-left: 10px;
  }
}

@media (max-width: 575.98px) {
  #second_ul {
    display: inline-flex;
    flex-wrap: wrap;
    width: auto;
  }

  .patternLogoContainerImages {
    margin: 40px 20px 40px 40px;
    height: auto;
  }

  .bottomRectangle {
    display: flex;
    flex-direction: column;
    padding: 20px 4% 20px 5%;
  }

  .bottomRectanglefirstColumn {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .bottomRectangleSecondColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .bottomRectanglefirstColumn button {
    width: 40vw;
    font-size: 20px;
  }

  #icPattern {
    width: 135px;
    height: 170px;
  }
  .patternLogoContainerImages div {
    margin-left: 40px;
  }

  .fromText {
    margin: 0px auto 0px;
    font-size: 30px;
  }
  #icAstiea {
    height: 130px;
    width: 223px;
  }

  .bottomRectanglefirstColumn button {
    height: 50px;
    font-size: 18px;
    width: auto;
  }
  .bottomRectangleThirdColumn {
    width: 100%;
    margin-left: 0px;
  }
  .telephoneNumberContainerFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-evenly;
  }
  .telephoneNumberContainerFooter p {
    display: inline-block;
  }
}

.offerContainer{
width: 80vw;
margin: 20px auto;
border: 1px solid black;
padding: 12px;
}
