@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

.Aboutus-root-container {
  font-family: "Arimo", sans-serif;
}

.topQuadrantAbout {
  width: 100%;
}

.topQuadrantFirstBarAbout {
  background-color: #fffcca;
  height: 370px;
}

.topQuadrantSecondBarAbout {
  background-color: #537780;
  height: 370px;
}

.topQuadrantFirstBarAbout .firstBarHalfAbout {
  width: 50%;
  padding: 50px 72px 25px 80px;
}

.topQuadrantFirstBarAbout .firstBarHalfAbout h4 {
  color: #000000;
  font-size: 43px;
}

.topQuadrantFirstBarAbout .firstBarHalfAbout p {
  color: #322f2f;
  font-size: 26px;
  font-weight: 300;

  margin-top: 20px;
  line-height: 1;
}

.topQuadrantFirstBarAbout .secondBarHalfAbout img {
  width: 50%;
  height: 370px;
}

.topQuadrantSecondBarAbout .secondBarHalfAbout img {
  width: 50%;
  height: 370px;
}

.topQuadrantSecondBarAbout .firstBarHalfAbout {
  width: 50%;
  padding: 80px 72px 25px 80px;
}

.topQuadrantSecondBarAbout .firstBarHalfAbout p {
  color: #f9e0ae;
  font-size: 26px;
  font-weight: 300;

  margin-top: 20px;
  line-height: 1;
}

.offerStripAbout {
  width: 95%;
  height: auto;
  margin: 20px auto;
  background-color: #d337d3;
  padding: 20px;
  border-radius: 5px;
}

.innerOfferStripAbout {
  border: 2px solid #bfbfbf;
  padding: 20px 5px 4px 5px;
  margin-left: 15px;
  margin-right: 20px;
}

.innerOfferStripAbout h5 {
  font-size: 24px;
  color: #ffffff;
  margin-left: 9px;
  margin-right: 20px;
}

.innerOfferStripAbout button {
  display: block;
  height: 32px;
  margin-left: 75px;
  margin-top: -4px;
  font-size: 15px;
  padding: 6px 12px 5px;
  background-color: #14c245;
  border: none;
  border-radius: 0;
  box-shadow: 0px 3px 3px #00000045;
}

.innerBoxbannerContainerAbout b {
  font-size: 25px;
}

.innerOfferStripAbout span {
  width: 4px;
  height: 35px;
  margin-top: -4px;
  margin-left: 9px;
  background-color: #bfbfbf;
}

.ourFeatuesAboutTagline {
  color: #537780;

  font-style: italic;
  font-weight: 500;
  margin: 50px auto;
  text-align: center;
}

.ourFeatuesAbout {
  width: 100%;
  padding: 15px 95px;
}

.ourFeatuesAboutFirstBar .secondBarHalfFeaturesAbout img {
  width: 50%;
  height: 375px;
  padding-left: 30px;
}

.ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout {
  width: 35%;
}

.ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout h3 {
  font-size: 43px;

  font-weight: 500;
}

.ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout p {
  color: #000000a9;
  font-size: 24px;
  font-weight: 300;

  margin-top: 20px;
  line-height: 1.2;
}

.firstCapitalFeatureAbout {
  font-size: 70px;
}

.readMoreButton {
  background-color: #11d3bc;
  border: 2px solid #13ae9c;
  border-radius: 0;
  padding: 12px 15px;
  width: 170px;
  margin-top: 20px;
  border-radius: 5px;
}

.readMoreButton:hover {
  background-color: #11d3bc;
  border: 2px solid #13ae9c;
}

.ourFeatuesAboutSecondBar {
  margin-top: 40px;
}

.ourFeatuesAboutSecondBar .secondBarHalfFeaturesAbout img {
  width: 50%;
  height: 375px;
  padding-right: 30px;
}

.ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout {
  width: 50%;
}

.ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout p {
  color: #000000a9;
  font-size: 26px;
  font-weight: 300;

  margin-top: 20px;
  line-height: 1;
}

.ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout h3 {
  font-size: 43px;

  font-weight: 500;
}

#ourFeatuesAboutThirdBar {
  margin-top: 40px;
}

#ourFeatuesAboutFourthBar {
  margin-bottom: 40px;
}

.processContainerAbout {
  width: 90%;
  margin: 10px auto;
}

.processContainerAbout h3 {
  font-size: 43px;

  font-weight: 500;
  margin-left: 25px;
  margin-top: 30px;
}

.processParaInAbout {
  font-size: 26px;

  font-weight: 300;
  margin-top: 30px;
  margin-left: 25px;
  color: #000000be;
  text-align: justify;
}

.diagramBannerContainer {
  display: flex;
}

.processContainerAbout .diagramContainerAbout {
  width: 70%;
  height: 610px;
  margin-top: 50px;
  padding-right: 3%;
}

.processContainerAbout .bannerContainerAbout {
  width: 30%;
  background-color: #ffc93c;
  height: 610px;
  margin-top: 50px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.innerBoxbannerContainerAbout {
  width: 86%;
  height: 560px;
  margin-top: 25px;
  margin-left: 25px;
  border: 2px solid #f2910a;
  text-align: center;
}

.innerBoxbannerContainerAbout h4 {
  color: #000000;
  font-size: 31px;
  margin-top: 80px;
  font-family: "Arimo", sans-serif;
  font-weight: 700;
}

.innerBoxbannerContainerAbout b {
  font-size: 60px;
  color: darkmagenta;
}
.innerBoxbannerContainerAbout img {
  width: 185px;
  margin-top: 60px;
}

.firstRowDiagramContainerAbout {
  width: 100%;
}

.blockDiagramContainerAbout {
  width: 28%;
  text-align: center;
}

.blockDiagramContainerAbout img {
  margin: 0px auto;
  width: 50px;
}

.blockDiagramContainerAbout p {
  font-size: 20px;
  margin: 20px auto;
}

.arrowDiagramContainerAbout {
  width: 8%;
}

.arrowDiagramContainerAbout img {
  width: 75%;
  margin-top: 20px;
}

.downArrowDiagramContainerAbout {
  width: 30%;
  margin-left: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.downArrowDiagramContainerAbout img {
  height: 45px;
}

.downArrowLeftDiagramContainerAbout {
  margin-left: 0px;
  width: 28%;
}

.About-us-what-our-designer-do ul li {
  font-size: 18px;
}
.firstBarHalfFeaturesAbout b {
  font-style: italic;
  color: #5f9ea0;
}

@media (min-width: 1400px) {
  .topQuadrantFirstBarAbout .firstBarHalfAbout {
    width: 58%;
    padding: 50px 72px 25px 80px;
  }
  .secondBarHalfAbout {
    width: 42%;
  }
  .topQuadrantFirstBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .topQuadrantSecondBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout {
    width: 58%;
    padding: 80px 72px 25px 80px;
  }
  .offerStripAbout {
    width: 1230px;
  }
  .processContainerAbout {
    width: 1400px;
    margin: 60px auto;
  }
  .ourFeatuesAboutTagline {
    width: 1400px;
    text-align: center;
  }
  .ourFeatuesAbout {
    width: 1400px;
    padding: 15px 95px;
    margin: 30px auto;
  }
  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout {
    width: 52%;
  }
  .ourFeatuesAboutFirstBar .secondBarHalfFeaturesAbout img {
    width: 100%;
  }
  .ourFeatuesAboutSecondBar .secondBarHalfFeaturesAbout img {
    width: 100%;
  }
  .secondBarHalfFeaturesAbout {
    width: 48%;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .ourFeatuesAboutFirstBar .secondBarHalfFeaturesAbout img {
    width: 100%;
    height: 375px;
    padding-left: 30px;
  }
  .ourFeatuesAboutSecondBar .secondBarHalfFeaturesAbout img {
    width: 100%;
    height: 375px;
    padding-right: 30px;
  }
  .topQuadrantFirstBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .topQuadrantSecondBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout p {
    margin-top: 0px;
  }
  element.style {
    width: 58%;
  }
  .contactUsTopPortionText div:nth-child(2) {
    width: 50%;
  }
  #ourFeatuesAboutThirdBar {
    margin-top: 40px;
    display: flex !important;
    justify-content: center;
  }
  .ourFeatuesAboutFirstBar .secondBarHalfFeaturesAbout img {
    width: 500px;
  }
  .ourFeatuesAboutFirstBar {
    justify-content: center;
  }
}

@media (min-width: 991.98px) and (max-width: 1199.98px) {
  .innerOfferStripAbout button {
    height: auto;
    margin-left: 16px;
  }
  .offerStripAbout {
    height: auto;
  }
  .topQuadrantFirstBarAbout .firstBarHalfAbout {
    width: 100%;
    padding: 50px 72px 25px 80px;
  }
  .topQuadrantFirstBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout {
    width: 70%;
    padding: 30px 30px 20px 30px;
  }
  .topQuadrantSecondBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .innerBoxbannerContainerAbout h4 {
    font-size: 23px;
  }
  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout {
    width: 70%;
  }
  .ourFeatuesAbout {
    width: 100%;
    padding: 15px 60px;
  }
  .ourFeatuesAboutFirstBar .secondBarHalfFeaturesAbout img {
    width: 100%;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout {
    width: 70%;
  }
  .ourFeatuesAboutSecondBar .secondBarHalfFeaturesAbout img {
    width: 100%;
    height: 375px;
    padding-right: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .topQuadrantFirstBarAbout .firstBarHalfAbout {
    padding: 40px 32px 5px 20px;
  }
  .topQuadrantFirstBarAbout .firstBarHalfAbout h4 {
    font-size: 33px;
  }
  .topQuadrantFirstBarAbout .firstBarHalfAbout p {
    font-size: 22px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout {
    padding: 40px 32px 25px 30px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout p {
    font-size: 22px;
  }
  .innerOfferStripAbout {
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .innerOfferStripAbout h5 {
    font-size: 18px;
  }
  .offerStripAbout {
    height: auto;
    padding: 10px;
  }

  .innerOfferStripAbout button {
    height: auto;
    margin-left: 0px;
  }

  .processContainerAbout h3 {
    font-size: 33px;
  }
  .processParaInAbout {
    font-size: 26px;
  }
  .diagramBannerContainer {
    flex-direction: column;
  }
  .processContainerAbout .diagramContainerAbout {
    width: auto;
  }
  .processContainerAbout .bannerContainerAbout {
    width: auto;
  }
  .innerBoxbannerContainerAbout h4 {
    margin-top: 20px;
  }
  .innerBoxbannerContainerAbout img {
    width: 185px;
    margin-top: auto;
  }

  .innerBoxbannerContainerAbout {
    height: 425px;
    margin-left: 45px;
  }

  .processContainerAbout .bannerContainerAbout {
    height: 480px;
  }
  .ourFeatuesAbout {
    padding: 15px 35px;
  }

  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout {
    width: 80%;
    padding: 20px;
  }
  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout h3 {
    font-size: 33px;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout h3 {
    font-size: 33px;
  }
  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout p {
    font-size: 20px;
    margin-top: auto;
    text-align: justify;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout p {
    font-size: 20px;
    margin-top: auto;
    text-align: justify;
  }
  .topQuadrantFirstBarAbout .firstBarHalfAbout {
    width: 100%;
    padding: 50px 72px 25px 80px;
  }
  .topQuadrantFirstBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout {
    width: 75%;
    padding: 80px 72px 25px 80px;
  }
  .topQuadrantSecondBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout {
    width: 75%;
    padding: 39px 30px 25px 40px;
  }
  .ourFeatuesAboutFirstBar .secondBarHalfFeaturesAbout img {
    width: 100%;
    height: 375px;
    padding-left: 0px;
    margin-top: 30px;
  }
  .ourFeatuesAboutSecondBar {
    margin-top: 40px;
    flex-direction: column-reverse;
  }
  .ourFeatuesAboutSecondBar .secondBarHalfFeaturesAbout img {
    width: 100%;
    height: 375px;
    padding-right: 30px;
    margin-top: 30px;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout {
    width: 100%;
  }
  #ourFeatuesAboutThirdBar {
    margin-top: 40px;
    flex-direction: column;
  }
  .ourFeatuesAboutTagline {
    margin: 30px auto;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 767.98px) and (min-width: 576px) {
  .topQuadrantFirstBarAbout .firstBarHalfAbout {
    padding: 40px 32px 5px 20px;
  }
  .topQuadrantFirstBarAbout .firstBarHalfAbout h4 {
    font-size: 33px;
  }
  .topQuadrantFirstBarAbout .firstBarHalfAbout p {
    font-size: 22px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout {
    padding: 40px 32px 25px 30px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout p {
    font-size: 22px;
  }
  .innerOfferStripAbout {
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .innerOfferStripAbout h5 {
    font-size: 18px;
  }
  .offerStripAbout {
    height: auto;
    padding: 10px;
  }

  .innerOfferStripAbout button {
    height: auto;
    margin-left: 0px;
  }

  .processContainerAbout h3 {
    font-size: 33px;
  }
  .processParaInAbout {
    font-size: 26px;
  }
  .diagramBannerContainer {
    flex-direction: column;
  }
  .processContainerAbout .diagramContainerAbout {
    width: auto;
  }
  .processContainerAbout .bannerContainerAbout {
    width: auto;
  }
  .innerBoxbannerContainerAbout h4 {
    margin-top: 20px;
  }
  .innerBoxbannerContainerAbout img {
    width: 185px;
    margin-top: auto;
  }

  .innerBoxbannerContainerAbout {
    height: 400px;
    margin-left: 45px;
  }

  .processContainerAbout .bannerContainerAbout {
    height: 450px;
  }
  .ourFeatuesAbout {
    padding: 15px 35px;
  }

  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout {
    width: auto;
  }
  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout h3 {
    font-size: 33px;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout h3 {
    font-size: 33px;
  }
  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout p {
    font-size: 20px;
    margin-top: auto;

    text-align: justify;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout p {
    font-size: 20px;
    margin-top: auto;

    text-align: justify;
  }

  .topQuadrantFirstBarAbout {
    background-color: #fffcca;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .topQuadrantFirstBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .topQuadrantSecondBarAbout {
    background-color: #537780;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
  }
  .topQuadrantSecondBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }
  .topQuadrantSecondBarAbout .firstBarHalfAbout {
    width: auto;
    padding: 40px 72px 25px 80px;
  }

  .ourFeatuesAboutFirstBar {
    flex-direction: column;
  }
  .ourFeatuesAboutFirstBar .secondBarHalfFeaturesAbout img {
    width: 100%;
    height: 375px;
    margin-top: 30px;
    padding-left: 0px;
  }
  .ourFeatuesAboutSecondBar {
    margin-top: 40px;
    flex-direction: column-reverse;
  }
  .ourFeatuesAboutSecondBar .secondBarHalfFeaturesAbout img {
    width: 100%;
    height: 375px;
    padding-right: 0px;
    margin-top: 30px;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout {
    width: auto;
  }
}

@media (max-width: 575.98px) {
  .topQuadrantAbout {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .topQuadrantFirstBarAbout,
  .topQuadrantSecondBarAbout {
    height: auto;
    display: flex;
  }

  .topQuadrantFirstBarAbout {
    flex-direction: column;
  }
  .topQuadrantSecondBarAbout {
    flex-direction: column-reverse;
  }

  .topQuadrantFirstBarAbout .firstBarHalfAbout,
  .topQuadrantSecondBarAbout .firstBarHalfAbout {
    width: auto;
  }

  .topQuadrantFirstBarAbout .secondBarHalfAbout img,
  .topQuadrantSecondBarAbout .secondBarHalfAbout img {
    width: 100%;
    height: 370px;
  }

  .offerStripAbout {
    height: auto;
  }

  .innerOfferStripAbout {
    flex-direction: column;
  }

  .innerOfferStripAbout img {
    width: 10px;
    height: 10px;
  }
  .innerOfferStripAbout h5 {
    margin-top: -36px;
    margin-left: 35px;
  }

  .innerOfferStripAbout button {
    width: auto;
    margin-left: auto;
  }

  .processContainerAbout h3 {
    font-size: 24px;
  }

  .processParaInAbout {
    font-size: 18px;
  }
  .processContainerAbout .bannerContainerAbout {
    width: auto;
  }
  .processContainerAbout .bannerContainerAbout {
    height: auto;
  }
  .innerBoxbannerContainerAbout {
    height: auto;
    margin-bottom: 25px;
  }

  .innerBoxbannerContainerAbout h4 {
    margin-top: 20px;
  }

  .innerBoxbannerContainerAbout img {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .diagramBannerContainer {
    display: flex;
    flex-direction: column;
  }

  .ourFeatuesAboutTagline {
    width: 87%;
  }

  .processContainerAbout .diagramContainerAbout {
    width: 100%;
    height: auto;
    margin-top: 50px;
    padding-right: 0%;
  }

  .ourFeatuesAbout {
    padding: 5%;
  }

  .ourFeatuesAboutFirstBar {
    display: flex;
    flex-direction: column-reverse;
  }
  .ourFeatuesAboutSecondBar {
    display: flex;
    flex-direction: column;
  }
  .ourFeatuesAboutFirstBar .secondBarHalfFeaturesAbout img {
    width: 100%;
    padding-left: 0px;
  }
  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout {
    width: auto;
  }

  .ourFeatuesAboutSecondBar .secondBarHalfFeaturesAbout img {
    width: 100%;
    padding-right: 0px;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout {
    width: auto;
  }

  /*  */
  .topQuadrantFirstBarAbout .firstBarHalfAbout h4 {
    font-size: 28px;
  }
  .topQuadrantFirstBarAbout .firstBarHalfAbout p,
  .topQuadrantSecondBarAbout .firstBarHalfAbout p {
    font-size: 20px;
  }
  .topQuadrantFirstBarAbout .firstBarHalfAbout,
  .topQuadrantSecondBarAbout .firstBarHalfAbout {
    padding: 20px 30px;
  }
  .blockDiagramContainerAbout p {
    font-size: 14px;
    margin: 20px auto;
  }
  .blockDiagramContainerAbout img {
    width: 40px;
  }
  .downArrowDiagramContainerAbout {
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .processParaInAbout,
  .processContainerAbout h3 {
    margin-left: 0px;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout p,
  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout p {
    font-size: 20px;
  }
  .ourFeatuesAboutSecondBar .firstBarHalfFeaturesAbout h3,
  .ourFeatuesAboutFirstBar .firstBarHalfFeaturesAbout h3 {
    font-size: 26px;
  }
  .firstCapitalFeatureAbout {
    font-size: 55px;
  }
  .innerBoxbannerContainerAbout b {
    font-size: 43px;
  }
}
