@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

.Gallery-root-container {
  font-family: "Arimo", sans-serif;
}

.galleryTopSection {
  display: flex;
}

.galleryHeading {
  font-size: 46px;
  color: #000000;
  margin: 40px 0px 40px 80px;
  font-weight: 500;
}

.offerStripGallery {
  height: 105px;
  margin-right: 25px;
  margin-left: 75px;
  padding: 20px 15px 5px 25px;
}

.offerStripGallery .innerOfferStripAbout {
  padding: 20px 5px 10px 0px;
  margin-left: 5px;
  margin-right: 0px;
  justify-content: center;
}

.offerStripGallery h5 {
  font-size: 19px;
  margin-right: 20px;
}
.offerStripGallery button {
  margin-left: 40px;
  font-size: 14px;
}

.offerStripGallery img {
  width: 8px;
  height: 8px;
}

.galleryImageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 96%;
  margin: 0px auto 60px auto;
}

.galleryImageContainer img {
  width: 32.8%;
  height: 350px;
  object-fit: cover;
  margin-bottom: 10px;
}

.Gallery-outer-shadow-container {
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  z-index: 5;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.Gallery-outer-shadow-container div {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.Gallery-outer-shadow-container span {
  background-color: #ffffff;
  border: 2px solid #7c731c;
  color: #7c731c;
  font-size: 24px;
  display: inline-block;
  margin: 40px auto 10px auto;
  font-size: 18px;
  padding: 10px 30px;
  cursor: pointer;
}

.Gallery-outer-shadow-container img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
  margin: 20px auto 10px auto;
  border: 2px solid #7c731c;
  background-color: #ffffff;
}
.innerOfferStripAbout span {
  width: 4px;
  height: 35px;
  margin-top: -4px;
  margin-left: 9px;
  background-color: #bfbfbf;
}

.Gallery-root-container {
  max-width: 1400px;
  margin: 0px auto;
}
@media (min-width: 1400px) {
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .Gallery-root-container {
    margin-top: 30px;
  }
  .offerStripGallery {
    margin-left: 30px;
    padding: 20px 15px 5px 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .Gallery-root-container {
    margin-top: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .Gallery-root-container {
    margin-top: 30px;
  }
  .offerStripGallery {
    margin-left: auto;
    margin-right: auto;
  }
  .galleryTopSection {
    display: flex;
    flex-direction: column;
  }
  .galleryHeading {
    margin: 20px 0px 20px 30px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .offerStripGallery {
    margin-left: auto;
    margin-right: auto;
  }
  .galleryHeading {
    margin: 40px 0px 20px 30px;
  }
  .galleryTopSection {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 575.98px) {
  .galleryImageContainer img {
    width: 96vw;
  }
  .offerStripGallery {
    height: auto;
    margin-right: 25px;
    margin-left: 25px;
    margin-top: 10px;
    width: 90vw;
    padding: 15px;
  }
  .galleryTopSection {
    display: flex;
    flex-direction: column;
  }
  .galleryHeading {
    margin: 40px 0px 20px 20px;
  }
  .offerStripGallery button {
    margin-left: auto;
  }
}
