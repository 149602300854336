@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

.headerStrip {
  background: #ff4646;
  color: #ffffff;
  font-family: "Arimo", sans-serif;
}

.headerStrip img {
  width: 14px;
}

.headerOfferStrip {
  background: #cca56b;
  color: #ffffff;
  width: auto;
  margin: 0 auto;
  padding: 2px;
  cursor: pointer;
}

.offers_link_header:link {
  text-decoration: none;
  display: none;
}

.headerOfferStrip img {
  width: 20px;
}

nav {
  background-color: #ffffff;
}
.navbar-nav {
  margin-left: auto;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #e57200;
  font-weight: 500;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000;
  font-weight: 400;
}

.navbar-nav button {
  margin-left: 15px;
  margin-right: 15px;
}
.navBarCompanyLogo {
  width: auto;
  height: 110px;
  object-fit: contain;
}

.getEstimateButton {
  background-color: #ff7f00;
  border: 1px solid #e57200;
  border-radius: 0;
  padding: 12px 15px;
  font-family: "Arimo", sans-serif;
}
#navbarNav {
  font-family: "Arimo", sans-serif;
}
#navbar-get-estimate {
  border-radius: 5px;
}
@media (min-width: 1400px) {
  .navBarCompanyLogo {
    margin-left: 20px;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  #navbar-get-estimate {
    position: absolute;
    top: 110px;
    right: 0px;
    z-index: 2;
    border-radius: 5px;
  }
  .navBarCompanyLogo {
    margin-left: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  #navbar-get-estimate {
    position: absolute;
    top: 110px;
    right: 0px;
    z-index: 2;
  }
  .navBarCompanyLogo {
    width: 170px;
    height: 110px;
    object-fit: contain;
  }
  .navBarCompanyLogo {
    margin-left: 10px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .navBarCompanyLogo {
    margin-left: 15px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .navBarCompanyLogo {
    margin-left: 10px;
  }
}

@media (max-width: 575.98px) {
  .navBarCompanyLogo {
    width: 140px;
    height: 60px;
    object-fit: contain;
    margin-left: 10px;
  }
  .carousel-caption h5 {
    font-size: 26px;
  }
}
