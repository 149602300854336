@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

.Packages-root-container {
  font-family: "Arimo", sans-serif;
}
.topBannerPackages {
  background-color: #ffc93c;
  height: 350px;
  padding-left: 70px;
  padding-top: 50px;
}

.topBannerPackagesHeading {
  font-size: 43px;
}

.topBannerPackagesDescription {
  width: 55vw;
  font-size: 25px;
  font-weight: 300;
  line-height: 1.1;
  margin-top: 49px;
}

.offerBoardPackages {
  width: 270px;
  height: 320px;
  background-color: #ff7f00;
  position: absolute;
  z-index: 100;
  top: 175px;
  right: 10%;
  border-radius: 5px;
}

.innerBoxofferBoardPackages {
  width: 86%;
  height: 86%;
  margin-top: 7%;
  margin-left: 7%;
  border: 2px solid #ffffff;
}

.innerBoxofferBoardPackages h5 {
  font-size: 18px;
  margin-left: 8%;
  margin-top: 18px;
  color: #ffffff;
}

.innerBoxofferBoardPackages b {
  font-size: 30px;
}

.innerBoxofferBoardPackages button {
  display: block;
  position: absolute;
  right: 15%;
  bottom: 11%;
  height: 29px;
  font-size: 14px;
  padding: 2px 12px 5px;
  background-color: #14c245;
  border: none;
  border-radius: 0;
  box-shadow: 0px 3px 3px #00000045;
}

.imgWarranty {
  width: 205px;
  margin-left: 50px;
  margin-top: 5px;
}

.packageHeading {
  color: #c24914;
  display: inline-block;
  font-size: 32px;
  margin-left: 100px;
  margin-top: 90px;
  padding-bottom: 15px;
  border-bottom: 3px solid #c24914;
}

.offerPackDiv {
  border: solid 3px #c24914;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 70px;
}

.offerPackDivHeader {
  background-color: #c3aa8c;
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.offerPackDivHeader h2 {
  margin-left: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 50px;
  color: #fff5f5;
}

.offerPackDivHeader strike {
  color: red;
  text-decoration-thickness: 1px;
}

.offerPackDivHeader strike span {
  color: #000000;
}
.offerPackDivBody {
  display: flex;
  margin: 40px 40px 0px 40px;
}

.offerPackDivLeftColumn {
  width: 50%;
}

.offerPackDivLeftColumn p {
  margin: 20px;
}

.offerPackDivRightColumn p {
  margin: 20px;
}

.offerPackDivBottom {
  margin: 20px 40px 20px 40px;
}

.offerPackDivBottom p {
  margin: 30px 20px 0px 0px;
}

.Packages-detail-content-container p {
  font-size: 20px;
  text-align: justify;
  background-color: #faebd7;
  border: 2px solid #cab8a1;
  font-style: italic;
  border-radius: 5px;
}
.topBannerPackagesDescriptionAuthor {
  font-size: 20px !important;
}
.Package-more-offers-button-container button {
  height: 40px;
  font-size: 14px;
  padding: 2px 12px 5px;
  background-color: #14c245;
  border: none;
  border-radius: 0;
  margin-left: auto;
  border-radius: 5px;
}
.Package-more-offers-button-container a {
  text-decoration: none !important;
}

.Package-more-offers-button-container {
  display: flex;
  justify-content: right;
}
.offerPackDiv {
  background-color: #fff8dc;
  border: none;
  border-radius: 15px;
  padding-bottom: 20px;
}
.Packages-star-image-heading span {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 21px;
}
.Packages-star-image-heading div {
  display: flex;
}

.Packages-star-image-heading span img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}
.Packages-star-image-heading {
  display: flex;
  align-items: center;
}
.Packages-asterisk {
  color: #ff0000;
  margin-right: 2px;
}
@media (min-width: 1400px) {
  .topBannerPackages {
    height: auto;
    padding-bottom: 50px;
  }

  .offerPackDiv {
    width: 1400px;
    margin: 60px auto;
    background-color: #fff8dc;
  }
  .Packages-star-image-heading {
    width: 1400px;
    margin: 20px auto;
  }

  .imgWarranty {
    margin-left: 0px;
  }
}

.topBannerPackagesDescription b {
  font-style: italic;
  color: #575656;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .topBannerPackages {
    height: auto;
    padding-bottom: 70px;
  }
  .offerBoardPackages {
    top: 200px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .topBannerPackages {
    height: auto;
    padding-bottom: 50px;
  }
  .offerBoardPackages {
    top: 205px;
  }
  .offerStripGallery {
    height: auto !important;
    margin-right: 25px;
    margin-left: 75px;
    padding: 8px 15px 8px 14px !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .topBannerPackages {
    height: auto;
    padding-bottom: 40px;
  }
  .topBannerPackagesDescription {
    width: 43%;
    text-align: justify;
  }
  .offerStripGallery {
    height: auto !important;
    margin-right: 25px;
    margin-left: 75px;
    padding: 8px 15px 8px 14px !important;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 767.98px) and (min-width: 576px) {
  .offerBoardPackages {
    display: none;
  }
  .topBannerPackagesDescription {
    width: auto;
  }
  .topBannerPackages {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    height: auto;
  }
  .packageHeading {
    margin-left: 20px;
  }
  .galleryTopSection {
    display: flex;
    flex-direction: column;
  }
  .offerStripGallery {
    height: auto !important;
    margin-right: 25px;
    margin-left: 5px;
    margin-top: 10px;
    padding: 8px 15px 8px 14px !important;
  }
  .galleryHeading {
    margin: 40px 0px 40px 40px;
  }
  .offerStripGallery {
    margin-left: 15px;
  }
}

@media (max-width: 575.98px) {
  .offerBoardPackages {
    display: none;
  }

  .topBannerPackages {
    padding-left: 30px;
  }

  .topBannerPackages {
    background-color: #ffc93c;
    height: auto;
    padding-bottom: 20px;
  }

  .topBannerPackagesDescription {
    width: auto;
  }
  .packageHeading {
    margin-top: 20px;
  }

  .offerPackDiv {
    margin: 30px 30px 0px 30px;
  }
  .offerPackDivHeader h2 {
    margin-left: 10px;
  }
  .offerPackDivLeftColumn {
    width: auto;
  }
  .offerPackDivBody {
    margin: 20px 20px 0px 20px;
  }

  .imgWarranty {
    margin: 15px auto;
    display: block;
  }
  .Packages-star-image-heading span img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  .Packages-star-image-heading {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .Packages-star-image-heading > div > span > label {
    font-size: 14px;
    padding: 0px 14px;
  }
  .packageHeading {
    width: 40%;
    margin-left: 0px;
    margin-top: 30px;
  }
  .offerPackDivHeader {
    flex-direction: column;
  }
  .offerPackDivHeader h2:nth-child(1) {
    margin-left: 10px;
    padding-top: 23px;
    padding-bottom: 0px;
  }
}
