@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

.Projects-root-container {
  font-family: "Arimo", sans-serif;
}

.topBannerProjects {
  background-color: #ea5455;
  height: 410px;
  padding-left: 70px;
  padding-top: 50px;
  color: #ffffff;
}
.topBannerProjectsHeading {
  font-size: 43px;
}
.topBannerProjectsDescription {
  width: 40vw;
  font-size: 18px;

  font-weight: 300;
  line-height: 1.1;
  margin-top: 25px;
  padding-right: 20px;
}

.ourWorksImageContainer {
  width: 53vw;
  height: 340px;
  background-color: #ff7f00;
  position: absolute;
  z-index: 100;
  top: 200px;
  right: 0;
}

.ourWorksImageContainer img {
  width: 53vw;
  height: 340px;
}

.projectListItemsContainer {
  padding: 50px 15px 110px 70px;
}

.projectListItemsContainer .listItemOne {
  display: flex;
}

.projectListItemsContainer .listItemOne {
  display: flex;
  position: relative;
}

.projectListItemsContainer .listItemOne .right .imageOne {
  width: 500px;
  height: 350px;
}

.projectListItemsContainer .listItemOne .right .imageTwo {
  width: 315px;
  height: 195px;
  position: absolute;
  top: 155px;
  right: 0;
}

.projectListItemsContainer .listItemOne .right .imageThree {
  width: 315px;
  height: 145px;
  position: absolute;
  top: 0;
  right: 0;
}

.projectListItemsContainer .listItemOne .left {
  width: 372px;
}

.listItemOne .left h3 {
  font-size: 30px;
  color: #f07b3f;
}

.listItemOne .left h6 {
  font-size: 25px;
  color: #000000;

  font-weight: 300;
  margin-top: 20px;
}

.listItemOne .left p {
  font-size: 16px;
  color: #000000c0;

  font-weight: 300;
  margin-top: 28px;
  padding-right: 50px;
}

.secondItem,
.thirdItem {
  margin-top: 30px;
}

.topBannerProjects i {
  color: #ffd700;
}
@media (min-width: 1400px) {
  .ourWorksImageContainer {
    width: 1400px;
    height: 300px;
    top: 320px;
    position: static;
    max-width: 1400px;
    margin: 0px auto;
  }
  .ourWorksImageContainer img {
    width: 1400px;
    height: 300px;
    object-fit: cover;
  }
  .projectListItemsContainer {
    max-width: 1400px;
    margin: 0px auto;
  }
  .topBannerProjects {
    height: 350px;
    max-width: 1400px;
    margin: 0px auto;
    padding-right: 50px;
    text-align: justify;
  }

  .topBannerProjectsDescription {
    width: auto;
  }
}

.Projects-testimonial-replacement-container {
  display: flex;
  justify-content: center;
  margin: 20px auto;
}
.Projects-testimonial-replacement-container img {
  width: 100%;
  object-fit: cover;
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .topBannerProjects {
    height: 490px;
  }
  .topBannerProjectsDescription {
    width: 49%;
  }
  .ourWorksImageContainer {
    width: 45%;
    height: 300px;
    top: 320px;
  }
  .ourWorksImageContainer img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .topBannerProjects {
    height: 490px;
  }
  .topBannerProjectsDescription {
    width: 49%;
  }
  .ourWorksImageContainer {
    width: 45%;
    height: 300px;
    top: 320px;
  }
  .ourWorksImageContainer img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .topBannerProjects {
    background-color: #ea5455;
    height: 410px;
    padding-left: 70px;
    padding-top: 50px;
    padding-right: 50px;
    color: #ffffff;
  }
  .topBannerProjectsDescription {
    width: auto;
  }

  .ourWorksImageContainer img {
    width: 100%;
    height: 340px;
    object-fit: cover;
  }
  .ourWorksImageContainer {
    display: block;
    position: static;
    width: 100%;
    height: 340px;
    background-color: #ff7f00;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .ourWorksImageContainer img {
    width: 100%;
    height: 340px;
    object-fit: cover;
  }
  .ourWorksImageContainer {
    display: block;
    position: static;
    width: 100%;
    height: 340px;
    background-color: #ff7f00;
  }
  .topBannerProjects {
    padding-left: 30px;
    padding-bottom: 45px;
    padding-top: 30px;
    padding-bottom: 45px;
    height: auto;
  }
  .topBannerProjectsDescription {
    width: auto;
    font-size: 22px;
    margin-top: 29px;
  }

  .topBannerProjectsHeading {
    font-size: 33px;
  }
  .projectListItemsContainer .listItemOne {
    flex-direction: column;
  }
  .projectListItemsContainer .listItemOne .right .imageOne {
    width: 340px;
    height: 300px;
  }
  .projectListItemsContainer .listItemOne .right .imageTwo {
    height: 148px;
    top: 240px;
  }
  .projectListItemsContainer .listItemOne .right .imageThree {
    top: 390px;
    height: 150px;
  }
  .projectListItemsContainer {
    padding: 50px 15px 110px 40px;
  }
  .projectListItemsContainer .listItemOne .left {
    width: auto;
  }
  .listItemOne .left h3 {
    font-size: 28px;
  }
  .Projects-testimonial-replacement-container img {
    width: 100%;
    object-fit: cover;
    max-width: 1400px;
  }
}

@media (max-width: 575.98px) {
  .topBannerProjects {
    height: auto;
    padding-left: 30px;
    padding-top: 25px;
  }
  .ourWorksImageContainer {
    position: static;
  }
  .topBannerProjectsDescription {
    width: auto;
    margin-top: 20px;
    padding-bottom: 50px;
  }
  .ourWorksImageContainer img {
    width: 100vw;
  }
  .projectListItemsContainer {
    padding: 50px 15px 30px 30px;
  }
  .projectListItemsContainer .listItemOne {
    flex-direction: column;
  }
  .projectListItemsContainer .listItemOne .right .imageOne {
    width: 55vw;
    height: 200px;
  }
  .projectListItemsContainer .listItemOne .right .imageTwo {
    width: 35vw;
    height: 105px;
    top: 270px;
  }
  .projectListItemsContainer .listItemOne .right .imageThree {
    width: 35vw;
    height: 93px;
    top: 377px;
  }

  .projectListItemsContainer .listItemOne .left {
    width: auto;
  }

  .Projects-testimonial-replacement-container img {
    width: 100%;
    object-fit: cover;
    max-width: 1400px;
  }
}
